import { FormFields } from "./FormFields";
import { ContractHolderInfo } from "./ContractHolderInfo";
import { CcInfo } from "./CcInfo";

export class ClientInformationsRequest {
    
    private LOBCode: string;
    private ProductCode: string;
    private Offer: string;

    private ContractHolderInfo: ContractHolderInfo;
    private CCInfo: CcInfo;

    constructor(lobCode: string, productCode: string, offer: string, cardInformationAccessKey: string, formFields: FormFields){
        this.LOBCode = lobCode;
        this.ProductCode = productCode;
        this.Offer = offer;
        this.ContractHolderInfo = new ContractHolderInfo(formFields);
        this.CCInfo = new CcInfo(cardInformationAccessKey, formFields);
    }
}