import React, { Component } from 'react';
import './PosteCanadaComponent.scss'
import FindLabelService from '../../../services/FindLabelService';
import { Observable } from 'rxjs/Rx'
import LocaleService from '../../../services/LocaleService';
import CPLogo from './canadapost.svg';
import FormErrors from '../../validations/FormErrors/FormErrors';
import { Utils } from '../../../utils';
import axios from 'axios';
import { EventEmitter } from 'events';
import { PosteCanadaCountyList } from '../../../classes/PosteCanadaCountyList';
const posteCanadaUtils = require('./posteCanadaUtils');

interface  PosteCanadaComponentProps {
  getParentForm: any;
  parentHandleInputChange: any;
  parentEventEmitter: EventEmitter;
}

class PosteCanadaComponent extends Component<PosteCanadaComponentProps> {

    private getParentForm: any;
    private parentHandleInputChange: any;
    private config: any;
    private suggestions: any[] = [];
    private fullSuggestionsList: any[] = [];
    private selectedSuggestionIndex: number = 0;
    private displaySuggestion: boolean = false;
    private limitDisplayedSuggestionsExceeded: boolean = false;
    private displayedSuggestionCurrentPage: number = 0;
    private displayCountrySelection: boolean = false;
    private displayCountryList: boolean = false;
    private displayManualAddress: boolean = false;
    private displayManualAddressPostalBox: boolean = false;
    private initialized: boolean = false;
    private canadaPosteServiceNotResponding: boolean = false;
    private currentCountry: string = "CAN";
    private invalidSearchDetected: boolean = false;
    private parentEventEmitter: EventEmitter;

    SUPPORTED_COUNTRIES = PosteCanadaCountyList
        
    MINIMUM_CHARACTER_FOR_POSTE_CANADA_SEARCH = 3;

    ACTIONS = {
      FIND: 'Find',
      RETRIEVE: 'Retrieve',
    };

    BACKSPACE_KEYCODE = 8;
    DELETE_KEYCODE = 46;
    COMA_KEYCODE = 188
    DASH_KEYCODE = 189

    constructor(props: any) {
      super(props);

      this.getParentForm = props.getParentForm;
      this.parentHandleInputChange = props.parentHandleInputChange;
      this.parentEventEmitter = props.parentEventEmitter;

      this.parentEventEmitter.on('openAddressPaneForVisibility', () => {
        this.openManualAddressSection();
      })

      this.config = {
        maxNumberOfItemsShown: 10,
        key: 'PJ55-RF42-CR99-HG59',
        endpoint: 'https://ws1.postescanada-canadapost.ca',
      }

      this.hideSuggestionEvent = this.hideSuggestionEvent.bind(this);
      this.hideCountrySelection = this.hideCountrySelection.bind(this);
      this.canadaInputFocus = this.canadaInputFocus.bind(this);
      this.canadaPosteInputUpDownEnterKeyHandler = this.canadaPosteInputUpDownEnterKeyHandler.bind(this);
      this.posteCanadaServiceNotResponding = this.posteCanadaServiceNotResponding.bind(this);
      this.setSelectedSuggestion = this.setSelectedSuggestion.bind(this);
      this.applyAddress = this.applyAddress.bind(this);
      this.changeCountry = this.changeCountry.bind(this);
      this.openManualAddressSection = this.openManualAddressSection.bind(this);
    }
    

    /**
     * Will initialize debounde event and other events for PosteCanada input
     */
    initialize(){
      if(!this.initialized){

        const input = document.getElementById('canada-poste-input');
        this.initialized = true;
        if(input){
            //for every keyup, map to current input value
            const canadaPostDebounce = Observable
            .fromEvent(input, 'keyup')
            .map(i => ({keyCode: (i as KeyboardEvent).keyCode, value: (i.target as HTMLInputElement).value}));

            canadaPostDebounce.subscribe(this.canadaPosteInputUpDownEnterKeyHandler)
    
            const debouncedInput = canadaPostDebounce.debounceTime(250);
    
            const subscribe = debouncedInput.subscribe(eventObj => {
              if(eventObj.value && 
                eventObj.value.length >= this.MINIMUM_CHARACTER_FOR_POSTE_CANADA_SEARCH && 
                  (this.validateKeypressIsValidForPosteCanadaInput(eventObj.keyCode) || 
                  eventObj.keyCode == this.BACKSPACE_KEYCODE || 
                  eventObj.keyCode == this.DELETE_KEYCODE ||
                  eventObj.keyCode == this.COMA_KEYCODE ||
                  eventObj.keyCode == this.DASH_KEYCODE)){
                    this.findCanadaPosteAddress(eventObj.value);
                  } else if(eventObj.value && 
                    eventObj.value.length < this.MINIMUM_CHARACTER_FOR_POSTE_CANADA_SEARCH) {
                    this.suggestions = [];
                    this.invalidSearchDetected = true;
                    this.forceUpdate();
                  }
            });
        }
      }
    }

    /**
     * Set selected suggestion in function of given PosteCanada address id
     * @param addressId - PosteCanada address id
     */
    setSelectedSuggestion(addressId: any){
      let suggestionIndex = this.suggestions.findIndex(function(suggestion){
        return suggestion.Id === addressId;
      })

      if(this.selectedSuggestionIndex !== suggestionIndex){
        this.selectedSuggestionIndex = suggestionIndex;
        this.forceUpdate();
      }
    }

    /**
     * Prevent to trigger the form submit on Enter keypress
     * @param event - Event sent by the listener
     */
    preventSubmitOnEnterKey(event: any){
      if(event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    }

    /**
     * Will liste to key UP/DOWN/Enter and move selectedSuggestionIndex up and down, or apply selected address on Enter key 
     * @param eventObj - The event object containing the keyCode
     */
    canadaPosteInputUpDownEnterKeyHandler(eventObj: any){

      if(this.displaySuggestion){
        switch (eventObj.keyCode){
          case 38:
            //UP
            if(this.selectedSuggestionIndex > 0){
              this.selectedSuggestionIndex--;
              this.forceUpdate();
            }
            break;
          case 40:
            //Down
            if(this.selectedSuggestionIndex < this.suggestions.length - 1){
              this.selectedSuggestionIndex++;
              this.forceUpdate();
            }
            break;
          case 13:
            //Enter
            this.displaySuggestion = false;
            this.forceUpdate();
            this.applyAddress(this.suggestions[this.selectedSuggestionIndex].Id)
            break;
        }
      }

    }

    /**
     * Validate that a keyCode is alphanumeric
     * @param keyCode - Key code to verify
     */
    validateKeypressIsValidForPosteCanadaInput(keyCode) {
      var keyChar = String.fromCharCode(keyCode);
      return /[A-Za-z0-9\$#]/.test(keyChar) ? keyChar : false;
    }

    /**
     * Will load the detail of the given address and prefill all required input
     * @param addressId - The PosteCanada address id to load
     */
    applyAddress(addressId: any){

      let suggestion = this.suggestions.find(function(suggestion){
        return suggestion.Id === addressId;
      })

      //If there is a next action with "Find" value it means there are sub address
      if(suggestion.Next == this.ACTIONS.FIND){
        this.findCanadaPosteAddress(suggestion.Text, suggestion.Id);
      } else {
        //Action is Retrieve
        this.getDetailsForCanadaPosteAddress(addressId).then((canadaPostDetailledAddressObj) => {

          //console.log('canadaPostDetailledAddressObj', canadaPostDetailledAddressObj);

          let fullAddressobj = posteCanadaUtils.transformCPO(canadaPostDetailledAddressObj)

          //console.log('fullAddressobj', fullAddressobj);

          this.getParentForm().fields.addressNumber = fullAddressobj.number;
          this.getParentForm().fields.addressStreet = fullAddressobj.street;
          this.getParentForm().fields.addressCity = fullAddressobj.city;
          this.getParentForm().getValidations("addressCity").length = 0;
          this.getParentForm().fields.addressProvince = fullAddressobj.provinceName;
          this.getParentForm().getValidations("addressProvince").length = 0;
          this.getParentForm().fields.addressPostalCode = fullAddressobj.postalCode;
          this.getParentForm().getValidations("addressPostalCode").length = 0;
          this.getParentForm().fields.addressCountry = fullAddressobj.countryName;
          this.getParentForm().getValidations("addressCountry").length = 0;
          this.getParentForm().fields.addressAppartment = fullAddressobj.extensionValue;

          this.getParentForm().fields.addressPostalBox = fullAddressobj.poBox;
          
          this.forceUpdate();
        }, (error) => this.posteCanadaServiceNotResponding())


        let canadaPosteInput = document.getElementById("canada-poste-input") as HTMLInputElement;
        if(canadaPosteInput != null){
          canadaPosteInput.value = `${suggestion.Text}, ${suggestion.Description.toUpperCase()}`;
        }
      }
    }

    /**
     * Return a desired number of item
     * @param items - Input items
     * @param max - Desired number
     */
    getSuggestionsFromApi(items, max, page = 0) {
      let suggestions = items;
      if (max && items.length > max) {
        suggestions = items.slice(max * page, (max * page) + max);
      }
      return suggestions;
    }

    suggestionsNextPage(event){
      event.preventDefault();
      event.stopPropagation();
      this.displayedSuggestionCurrentPage++;
      const suggestions = this.getSuggestionsFromApi(this.fullSuggestionsList, this.config.maxNumberOfItemsShown, this.displayedSuggestionCurrentPage);
      this.suggestions = suggestions;
      this.forceUpdate();
    }
    
    suggestionsPreviousPage(event){
      event.preventDefault();
      event.stopPropagation();
      this.displayedSuggestionCurrentPage--;
      const suggestions = this.getSuggestionsFromApi(this.fullSuggestionsList, this.config.maxNumberOfItemsShown, this.displayedSuggestionCurrentPage);
      this.suggestions = suggestions;
      this.forceUpdate();
    }

    
    getDisplayedSuggestionNextPageSection(){
      if(this.limitDisplayedSuggestionsExceeded && this.displayedSuggestionCurrentPage < Math.floor(this.fullSuggestionsList.length / this.config.maxNumberOfItemsShown)){
        return (
          <li className="suggestions-next-page" onClick={(event) => this.suggestionsNextPage(event)}><span className="font-icons">&#xe039;</span></li>
        )

      }else {
        return "";
      }
    }
    
    getDisplayedSuggestionPreviousPageSection(){
      if(this.displayedSuggestionCurrentPage !== 0){
        return (
          <li className="suggestions-previous-page" onClick={(event) => this.suggestionsPreviousPage(event)}><span className="font-icons">&#xe608;</span></li>
        )

      }else {
        return "";
      }
    }

    /**
     * Return an address line component divided by multiple Span element in order to highlight range desired
     * @param address The address line original text
     * @param highlights The desired highlight array, each highlight begin and end been coma separate
     */
    getHightlightedSuggestion(address, highlights){
      if(typeof highlights !== 'undefined' && highlights !== ""){
        let hightlightsArray = highlights.split(",");
      
        let hightlightsResult: any[] = [];

        for(let i = 0; i < hightlightsArray.length; i++){
          let highlightRange = hightlightsArray[i].split("-");

          if(i == 0){
            let firstHightlight = (
              address.slice(0, highlightRange[0])
            );
            hightlightsResult.push(firstHightlight);
          }else {
            let spaceBetweenHighlight = (
              address.slice(hightlightsArray[i-1].split("-")[1], highlightRange[0])
            );
            hightlightsResult.push(spaceBetweenHighlight);
          }


          let currentHightlight = (<b key={Utils.getElementListUniqueKey('highlight')}>{address.slice(highlightRange[0], highlightRange[1])}</b>);
          hightlightsResult.push(currentHightlight);

          if(i == hightlightsArray.length -1){
            let endOfhightlight = (
            address.slice(highlightRange[1], address.length)
            )
            hightlightsResult.push(endOfhightlight);
          }
        }

        return (<span>{hightlightsResult.map((element) => {
          return element
        })}</span>);
      } else {
        return (<span>{address}</span>)
      }
      
    }

    /**
     * Return the list of suggestion component
     */
    getSuggestionsComponentList(){
      if(this.displaySuggestion && !this.displayCountryList){
        
        if(this.invalidSearchDetected){
          return (
          <ul>
            <li className="enter-address-manually-suggestion">{FindLabelService.findLabel('CanadaPosteInput_NoSuggestionFound_Prefix')} <a onClick={this.openManualAddressSection}>{FindLabelService.findLabel('CanadaPosteInput_NoSuggestionFound_Link')}</a>.</li>
            {this.getInputDropdownBottomSection()}
          </ul>)
        } else {
          var listItems = this.suggestions.map((item) => {
            return (
              <li onClick={() => this.applyAddress(item.Id)} 
                  onMouseOver={() => this.setSelectedSuggestion(item.Id)} 
                  data-selected={this.suggestions[this.selectedSuggestionIndex] == item ? 'true' : 'false'} 
                  key={Utils.getElementListUniqueKey(item.Id)}>
                {this.getHightlightedSuggestion(item.Text, item.Highlight)}
                <em>{item.Description}</em>
              </li>
            );
          });
      
          return (
              <ul>
                {this.getDisplayedSuggestionPreviousPageSection()}
                {listItems}
                {this.getDisplayedSuggestionNextPageSection()}
                {this.getInputDropdownBottomSection()}
              </ul>
          );
        }
      }
      else {
        return "";
      }
    }

    changeCountry(event, newCountryId){
      event.preventDefault();
      event.stopPropagation();
      this.currentCountry = newCountryId;
      this.displayCountryList = false;
      this.forceUpdate();

      let canadaPosteInput = document.getElementById('canada-poste-input') as HTMLInputElement;
      if(canadaPosteInput != null){
        if(this.displaySuggestion){
          this.findCanadaPosteAddress(canadaPosteInput.value);
        }
        setTimeout(function(){
          
          canadaPosteInput.focus();
        },100)
      }
    }

    getFlagPosition(index){
        return "-1px -" + (index * 16 + 2) + "px";
    }
    
    /**
     * Return the list of suggestion component
     */
    getCountrySelectionComponent(){
      if(this.displayCountrySelection && (!this.displaySuggestion || this.displayCountryList)){
        let listItems;
        if(this.displayCountryList){
          listItems = this.SUPPORTED_COUNTRIES.map((item) => {
            return (
              <li onClick={(event) => this.changeCountry(event, item.iso3)} 
                  key={Utils.getElementListUniqueKey(item.iso3)}>
                  <div className="pcaflag-list-container"><div className="pcaflag" style={{backgroundPosition: this.getFlagPosition(item.flag)}} ></div></div>
                  <div className="pca-country-name">{LocaleService.getLanguage() === 'en' ? item.name : item.name_fr}</div>
              </li>
            );
          });
      
        }
        
        return (
          <ul className="country-list">
            {listItems}
            {this.getInputDropdownBottomSection()}
          </ul>
      );
      }
      else {
        return "";
      }
    }
    
    toggleCountryList(event){
      event.preventDefault();
      event.stopPropagation();
      this.displayCountryList = !this.displayCountryList;
      if(this.displayCountryList){
        this.showCountrySelection(event);
      }
      this.forceUpdate();
    }

    getCurrentCountry(){
      return this.SUPPORTED_COUNTRIES.find((country) => {
        return country.iso3 === this.currentCountry;
      })
    }

    getCountryListToggleContainer(){
      let curCountry = this.getCurrentCountry();
      if(curCountry != null){
        return (
          <div className={'country-list-toggle' + (this.displayCountryList ? ' list-expended' : '')} onClick={(e) => this.toggleCountryList(e)}><div className="pcaflag" style={{backgroundPosition: this.getFlagPosition(curCountry.flag)}}></div></div>
        )
      }
    }

    canadaInputFocus(event){
      event.preventDefault();
      event.stopPropagation();
      if(this.suggestions && this.suggestions.length > 0){
        this.showSuggestion();
      } else {
        this.showCountrySelection(event)
      }
    }

    showCountrySelection(event){
      event.preventDefault();
      event.stopPropagation();
      setTimeout(() => {
        window.addEventListener("click", this.hideCountrySelection);
      },300)
      this.displayCountrySelection = true;
      this.forceUpdate();
    }

    hideCountrySelection(event){
      if(event.target.id !== 'canada-poste-input'){
        window.removeEventListener("click", this.hideCountrySelection);
        this.displayCountrySelection = false;
        this.displayCountryList = false;
        this.forceUpdate();
      }
    }

    getInputDropdownBottomSection(){
      return (
        <li className="bottom-section">
          <img src={process.env.PUBLIC_URL + '/assets/images/' + (LocaleService.getLanguage() === 'en' ? 'address_complete_logo_en.png' : 'address_complete_logo_fr.png')} />
          {this.getCountryListToggleContainer()}
          <span className="select-country">{FindLabelService.findLabel("CanadaPosteInput_ChangeCountry")}</span>
        </li>
      )
    }

    /**
     * Trigger to hide the list of suggestion component
     */
    hideSuggestionEvent(){
      this.displaySuggestion = false;
      this.forceUpdate();
      window.removeEventListener("click",this.hideSuggestionEvent);
    }

    escapeHtml(str)
    {
        var map =
        {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;',
            "#": ''
        };
        return str.replace(/[&<>"'#]/g, function(m) {return map[m];});
    }
    
    /**
     * Will query PosteCanada with a searchValue
     * @param searchValue - Search value
     * @param lastId - Will narrow down the search to sub-element if provided
     */
    async findCanadaPosteAddress(searchValue, lastId = ''){

      const url = `${this.config.endpoint}/AddressComplete/Interactive/${this.ACTIONS.FIND}/v2.00/json3.ws?Key=${this.config.key}&Country=${this.currentCountry}&SearchTerm=${this.escapeHtml(searchValue)}&LastId=${lastId}&SearchFor=Everything&LanguagePreference=${LocaleService.getLanguage()}&$block=true&$cache=true`;
      axios.get(url)
        .then((response) => response.data)
        .then((json) => {

          if(json.Items.length === 0 || (json.Items.length > 0 && typeof json.Items[0].Error !== 'undefined' && json.Items[0].Error === '1001')){
            this.invalidSearchDetected = true;
          } else {
            this.invalidSearchDetected = false;
            this.fullSuggestionsList = json.Items;
            const suggestions = this.getSuggestionsFromApi(json.Items, this.config.maxNumberOfItemsShown);
            
            this.limitDisplayedSuggestionsExceeded = (suggestions.length < json.Items.length);
            this.displayedSuggestionCurrentPage = 0;
  
            this.suggestions = suggestions;
            this.selectedSuggestionIndex = 0;
          }
          this.showSuggestion();
        })
        .catch((error) => this.posteCanadaServiceNotResponding());
    }

    showSuggestion(){
      this.displaySuggestion = true;
      this.forceUpdate();
      setTimeout(() => {
        window.addEventListener("click", this.hideSuggestionEvent);
      }, 300);
    }

    posteCanadaServiceNotResponding(){
      this.canadaPosteServiceNotResponding = true;
      let posteCanadaInput = document.getElementById("canada-poste-input");
      if(posteCanadaInput ){
        (posteCanadaInput as HTMLInputElement).value = "";
      }
      this.forceUpdate();
    }

    /**
     * Will retrieve detail about a PosteCanada address
     * @param addressId - The PosteCanada id of the address
     */
    getDetailsForCanadaPosteAddress(addressId){
      return new Promise((resolve, reject) => {
        const url = `${this.config.endpoint}/AddressComplete/Interactive/${this.ACTIONS.RETRIEVE}/v2.00/json3.ws?Key=${this.config.key}&Country=CAN&Id=${addressId}&LanguagePreference=${LocaleService.getLanguage()}&$block=true&$cache=true`;
        axios.get(url)
          .then((response) => response.data)
          .then((json) => {
            resolve(json.Items[0]);
          })
          .catch(() => {
            reject();
          });
      })
    }
     
    /**
     * Trigger the animation to display/hide manual address inputs
     */
    toggleDisplayManualAddress(){
      this.displayManualAddress = !this.displayManualAddress;
      
      let manualAddressSection = document.getElementById("manual-address-section");
      if(manualAddressSection != null){
        if(this.displayManualAddress){
          manualAddressSection.style.maxHeight='800px';
        } else {
          manualAddressSection.style.maxHeight='0';
        }
      }

      this.forceUpdate();
    }

    openManualAddressSection(){
      if(!this.displayManualAddress){
        this.toggleDisplayManualAddress();
      }
    }
     
    /**
     * Trigger the animation to display/hide postal box or rural road inputs
     */
    toggleDisplayManualAddressPostalBox(){
      this.displayManualAddressPostalBox = !this.displayManualAddressPostalBox;
      
      let manualAddressPostalBoxSection = document.getElementById("manual-address-postal-box-section");
      if(manualAddressPostalBoxSection != null){
        if(this.displayManualAddressPostalBox){
          manualAddressPostalBoxSection.style.maxHeight='200px';
        } else {
          manualAddressPostalBoxSection.style.maxHeight='0';
        }
      }

      this.forceUpdate();
    }

    /**
     * Return the manual address inputs section
     */
    getManualAddressSection(){
      return (
        <div id="manual-address-section">
          <div className="row row-1">
            <div className="input-address input-address-column-small-medium">
              <label htmlFor="addressNumber">{FindLabelService.findLabel("creditCardInfoSectionAddressNumber")}</label>
              <input name="addressNumber" id="addressNumber" value={this.getParentForm().fields.addressNumber} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange} />
              <FormErrors getValidations={this.getParentForm().getValidations} field={'addressNumber'}/>
            </div>
            <div className="input-address input-address-column-medium-large">
              <label htmlFor="addressStreet" >{FindLabelService.findLabel("creditCardInfoSectionAddressStreet")}</label>
              <input name="addressStreet" id="addressStreet" value={this.getParentForm().fields.addressStreet} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange}/>
              <FormErrors getValidations={this.getParentForm().getValidations} field={'addressStreet'}/>
            </div>
            <div className="input-address input-address-column-small-medium">
              <label htmlFor="addressAppartment" >{FindLabelService.findLabel("creditCardInfoSectionAddressAppartment")}</label>
              <input name="addressAppartment" id="addressAppartment" value={this.getParentForm().fields.addressAppartment} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange}/>
              <FormErrors getValidations={this.getParentForm().getValidations} field={'addressAppartment'}/>
            </div>
          </div>
          <div className="row row-2">
            <div className="input-address input-address-column-small">
              <label htmlFor="addressCity">{FindLabelService.findLabel("creditCardInfoSectionAddressCity")}</label>
              <input name="addressCity" id="addressCity" value={this.getParentForm().fields.addressCity} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange} />
              <FormErrors getValidations={this.getParentForm().getValidations} field={'addressCity'}/>
            </div>
            <div className="input-address input-address-column-small-medium">
              <label htmlFor="addressProvince" >{FindLabelService.findLabel("creditCardInfoSectionAddressProvince")}</label>
              <input name="addressProvince" id="addressProvince" value={this.getParentForm().fields.addressProvince} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange}/>
              <FormErrors getValidations={this.getParentForm().getValidations} field={'addressProvince'}/>
            </div>
            <div className="input-address input-address-column-small-medium">
              <label htmlFor="addressCountry" >{FindLabelService.findLabel("creditCardInfoSectionAddressCountry")}</label>
              <input name="addressCountry" id="addressCountry" value={this.getParentForm().fields.addressCountry} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange}/>
              <FormErrors getValidations={this.getParentForm().getValidations} field={'addressCountry'}/>
            </div>
            <div className="input-address input-address-column-small">
              <label htmlFor="addressPostalCode" >{FindLabelService.findLabel("creditCardInfoSectionAddressPostalCode")}</label>
              <input name="addressPostalCode" id="addressPostalCode" value={this.getParentForm().fields.addressPostalCode} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange}/>
              <FormErrors getValidations={this.getParentForm().getValidations} field={'addressPostalCode'}/>
            </div>
          </div>
          
          <div onClick={() => this.toggleDisplayManualAddressPostalBox()} className="enter-address-postal-box">
              {!this.displayManualAddressPostalBox ? (<span className="font-icons">&#xe038;</span>) : (<span className="font-icons">&#xe039;</span>)}
              <span className="text">{FindLabelService.findLabel("addressDisplayManualInputsPostalBox")}</span>
          </div>
          {this.getManualAddressPostalBoxSection()}
        </div>
      )
    }

    /**
     * Return the postal box and rural road inputs section
     */
    getManualAddressPostalBoxSection(){
      return (
        <div id="manual-address-postal-box-section">
          <div className="row row-3">
            <div className="input-address input-address-column-small">
              <label htmlFor="addressPostalBox">{FindLabelService.findLabel("creditCardInfoSectionAddressPostalBox")}</label>
              <input name="addressPostalBox" id="addressPostalBox" value={this.getParentForm().fields.addressPostalBox} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange}/>
              <FormErrors getValidations={this.getParentForm().getValidations} field={'addressPostalBox'}/>
            </div>
            <div className="input-address input-address-column-medium">
              <label htmlFor="addressBranch" >{FindLabelService.findLabel("creditCardInfoSectionAddressBranch")}</label>
              <input name="addressBranch" id="addressBranch" value={this.getParentForm().fields.addressBranch} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange}/>
              <FormErrors getValidations={this.getParentForm().getValidations} field={'addressBranch'}/>
            </div>
            <div className="input-address input-address-column-medium">
              <label htmlFor="addressRuralRoad" >{FindLabelService.findLabel("creditCardInfoSectionAddressRuralRoad")}</label>
              <input name="addressRuralRoad" id="addressRuralRoad" value={this.getParentForm().fields.addressRuralRoad} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange}/>
              <FormErrors getValidations={this.getParentForm().getValidations} field={'addressRuralRoad'}/>
            </div>
          </div>
        </div>
      )
    }

    render() {
      setTimeout(() => this.initialize(), 0);
      
      return (
        <div className="row no-bottom-margin">
            <label>{FindLabelService.findLabel("creditCardInfoSectionAddress")}</label>
            <div className="adresse-wrapper">
              <input onFocus={(event) => this.canadaInputFocus(event)} onKeyDown={this.preventSubmitOnEnterKey} disabled={this.canadaPosteServiceNotResponding || this.displayCountryList} type="text" autoComplete="off" id="canada-poste-input" placeholder={this.canadaPosteServiceNotResponding ? FindLabelService.findLabel("errorCanadaPosteServiceNotResponding") : FindLabelService.findLabel("posteCanadaAddressInputPlaceholder")}/>
            </div>
            <div className="suggestion-wrapper">
              {this.getSuggestionsComponentList()}
              {this.getCountrySelectionComponent()}
            </div>
            <div className="address-suggestion-example">
              {FindLabelService.findLabel("ExampleAbreviation")} {FindLabelService.findLabel("PosteCanadaInput_Example")}
            </div>
            <div onClick={() => this.toggleDisplayManualAddress()} className="enter-address-manually">
              {!this.displayManualAddress ? (<span className="font-icons">&#xe038;</span>) : (<span className="font-icons">&#xe039;</span>)}
              <span className="text">{FindLabelService.findLabel("addressDisplayManualInputs")}</span>
            </div>
            {this.getManualAddressSection()}
        </div>
      );
    }
  }
  
export default PosteCanadaComponent;
  