import React, { Component } from 'react';
import './FormAgreementSection.scss';
import FindLabelService from '../../../services/FindLabelService';
import FindFormSettingService from '../../../services/FindFormSettingService';

interface  FormAgreementSectionProps {
  withConsent?: boolean;
  creditCardLastDigits?: string;
  consentAction?: any;
}

class FormAgreementSection extends Component<FormAgreementSectionProps> {
  private withConsent: string;
  private creditCardLastDigits: string;
  private consentAction: any;
  private whiteLabelingEnabled: boolean;

  constructor(props: any) {
    super(props);

    this.withConsent = props.withConsent;
    this.consentAction = props.consentAction;
    this.creditCardLastDigits = props.creditCardLastDigits || 'XXXX';
    
    this.whiteLabelingEnabled = FindFormSettingService.findSetting("WhiteLabelingEnabled");
  }

  getAggreementMessage(){
    if(this.whiteLabelingEnabled){
      return FindLabelService.findLabel("ReviewSection_CustomAgreement");
    } else {
      return FindLabelService.findLabel("reviewSectionAgreement");
    }
  }
  
  render() {

    if(this.withConsent){
      return (
        <div className="section form-agreement-section">
          {this.getAggreementMessage()}
          <div className="consent-section">
            <input id="consent" type="checkbox" onChange={this.consentAction} />
            <label className="label-consent" htmlFor="consent" >{FindLabelService.findLabel("Consent_Part1")} {this.creditCardLastDigits} {FindLabelService.findLabel("Consent_Part2")}</label>
          </div>
        </div>
      );
    } else {
      return (
        <div className="section form-agreement-section">
          {this.getAggreementMessage()}
        </div>
      );
    }

  }
}
  
  
export default FormAgreementSection;
  