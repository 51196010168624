import { FormFields } from "./FormFields";
import { ClientInformationsRequest } from "./ClientInformationsRequest";

export class UpdateCCRequest extends ClientInformationsRequest {
    private CardInformationAccessKey: string;
    private consentIndicator: boolean;

    constructor(lobCode: string, productCode: string, offer: string, consentIndicator: boolean, cardInformationAccessKey: string, formFields: FormFields){
        super(lobCode, productCode, offer, cardInformationAccessKey, formFields);
        this.consentIndicator = consentIndicator
        this.CardInformationAccessKey = cardInformationAccessKey;
    } 
}