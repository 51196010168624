import React, { Component } from 'react';
import './PageNotFound.scss';
import FindLabelService from '../../services/FindLabelService'

class PageNotFound extends Component {

  render() {

    return (
      <div className="page-not-found">
        <h2>{FindLabelService.findLabel("404_Not_found") !== "404_Not_found" ? FindLabelService.findLabel("404_Not_found") : "Erreur 404 : page non trouvée."}</h2>
        <h3>{FindLabelService.findLabel("404_Not_found_message") !== "404_Not_found_message" ? FindLabelService.findLabel("404_Not_found_message") : ""}</h3>
      </div>
    );
  }
}


export default PageNotFound;
