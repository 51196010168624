import React, { Component } from 'react';
import FindLabelService from '../../services/FindLabelService'
import StepFillFormOTP from './FormSteps/StepOne/StepFillFormOTP'
import StepReviewFormOTP from './FormSteps/StepTwo/StepReviewFormOTP'
import StepConfirmationOTP from './FormSteps/StepThree/StepConfirmationOTP';
import { Router as Router, Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import history from "../../history";
import LoadingScreenService from '../../services/LoadingScreenService';
import { LOBProducts } from '../../classes/LOBProducts';
import StartupService from '../../services/StartupService';
import PageNotFound from '../error/PageNotFound';

class FormOTP extends Component<{}, {invalidForm: boolean}> {

  constructor(props: any) {
    super(props);

    if(LOBProducts.isValidForm(StartupService.getProductOffer(), 'otp')){
      this.state = {
        invalidForm: false
      }
    }
    else {
      this.state = {
        invalidForm: true
      }
    }
  }

  render() {

    if(this.state.invalidForm){
      return (
          <PageNotFound />
      )
    } else {
      return (
        <div className="form form-otp">
          <div className="pane-wrapper">
            <div className="left-side-pane">
              <div className="product-infos">
                <h1>{FindLabelService.findLabel("formOTPTitle")}</h1>
                <h2>{FindLabelService.findLabel("UpdateCC_FormName")}</h2>
              </div>
            </div>
            <div className="right-side-pane">
              <Router history={history}>
                <Switch>
                  <Route path="/:ProductOffer/payment/review" component={StepReviewFormOTP} />
                  <Route path="/:ProductOffer/payment/confirmation" component={StepConfirmationOTP} />
                  <Route path="/:ProductOffer/payment" component={StepFillFormOTP} />
                </Switch>
              </Router>
            </div>
          </div>
        </div>
      );

    }

    return 
  }
}


export default FormOTP;
