import React from "react";
import { Provider, Subscribe, Container } from "unstated";
import FindFormSettingService from "./FindFormSettingService";
import LocaleService from "./LocaleService";
import { CreditCardCompanies } from "../classes/CreditCardCompanies";

const DEFAULT_MASK_PAN = "xxxx";
const FIRSTNAME_LASTNAME_MINIMUM_CHARS_NBR = 2;
const PRIME_AMOUNT_RANGE = [0.05, 100000];

export class FormServiceContainer extends Container {

    constructor() {
        super();

        // The state will be available to any component we inject
        // the Container instance into
        this.state = {
        };
    }


    /********************************************************************************
     * These methods will also be avaiable anywhere we inject our container context
     ********************************************************************************/

    /**
     * Apply diferent kind of validation depending on the given field name
     * @param {*} fieldName - Name of the field to test
     * @param {*} value - Current value inside the field to test
     */
    validateField(fieldName, value, firstValidation = false) {

        let fieldValidationErrors = [];

        if ((typeof this.fieldValidatedAtLeastOnceOnBlur !== 'undefined' && this.fieldValidatedAtLeastOnceOnBlur.indexOf(fieldName) !== -1) || firstValidation) {
            switch (fieldName) {
                case 'gpn':
                    if (FindFormSettingService.findSetting("FieldsIdAlphanum") === "1") {
                        let realValue = value.replace(/_/g, "");
                        if (realValue != null && realValue.length < +FindFormSettingService.findSetting("FieldsIdDigits")) {
                            fieldValidationErrors.push("ErrorMessage_Lenght_ID");
                        }
                    }
                    else {
                        let realValue = value.replace(/\D/g, "");
                        if (realValue == null || realValue.length === 0 || value.replace(/[0]/g, "").length === 0) {
                            fieldValidationErrors.push("ErrorMessage_Empty_ID");
                        } else if (value != null && realValue.length < +FindFormSettingService.findSetting("FieldsIdDigits")) {
                            fieldValidationErrors.push("ErrorMessage_Lenght_ID");
                        } else if (value != null && value.replace(/_/g, "").length !== 0 && !(/\d/.test(realValue))) {
                            fieldValidationErrors.push("ErrorMessage_Format_ID");
                        }
                    }
                    break;
                case 'memberId':
                    if (FindFormSettingService.findSetting("FieldsMemberIdAlphanum") === "1") {
                        let realValue = value.replace(/_/g, "");
                        if (realValue != null && realValue.length < +FindFormSettingService.findSetting("FieldsMemberIdDigits")) {
                            fieldValidationErrors.push("ErrorMessage_Lenght_ID");
                        }
                    }
                    else {
                        if (FindFormSettingService.findSetting("DisplaySecondaryFieldId") === "1") {
                            let realValue = value.replace(/\D/g, "");
                            if (value == null || realValue.length === 0 || value.replace(/[0]/g, "").length === 0) {
                                fieldValidationErrors.push("ErrorMessage_Empty_ID");
                            } else if (value != null && realValue.length < +FindFormSettingService.findSetting("FieldsMemberIdDigits")) {
                                fieldValidationErrors.push("ErrorMessage_Lenght_ID");
                            } else if (value != null && value.replace(/_/g, "").length !== 0 && !(/\d/.test(realValue))) {
                                fieldValidationErrors.push("ErrorMessage_Format_ID");
                            }
                        }
                    }
                    break;
                case 'firstName':
                    if (value == null || value.length === 0) {
                        fieldValidationErrors.push("ErrorMessage_Empty_" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1));
                    } else if (value != null && value.length < FIRSTNAME_LASTNAME_MINIMUM_CHARS_NBR) {
                        fieldValidationErrors.push("ErrorMessage_MinimumChars_Name");
                    } else if (value != null && !(/^\D+$/.test(value))) {
                        fieldValidationErrors.push("ErrorMessage_NoNumber_Name");
                    }
                    break;
                case 'lastName':
                    if (value == null || value.length === 0) {
                        fieldValidationErrors.push("ErrorMessage_Empty_" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1));
                    } else if (value != null && value.length < FIRSTNAME_LASTNAME_MINIMUM_CHARS_NBR) {
                        fieldValidationErrors.push("ErrorMessage_MinimumChars_Name");
                    } else if (value != null && !(/^\D+$/.test(value))) {
                        fieldValidationErrors.push("ErrorMessage_NoNumber_Name");
                    }
                    break;
                case 'totalPremium':
                    if (value == null || value.length === 0) {
                        fieldValidationErrors.push("ErrorMessage_Empty_Amount");
                    } else {
                        if (LocaleService.getLanguage() === 'en') {
                            let realValue = value.replace(/[$,]/g, '');
                            if (!(PRIME_AMOUNT_RANGE[0] <= +realValue && +realValue <= PRIME_AMOUNT_RANGE[1])) {
                                fieldValidationErrors.push("ErrorMessage_NotInRange_Amount");
                            }
                        }
                        else {
                            let realValue = value.replace(/,/g, ".").replace(/[$\s]/g, '');
                            if (!(PRIME_AMOUNT_RANGE[0] <= +realValue && +realValue <= PRIME_AMOUNT_RANGE[1])) {
                                fieldValidationErrors.push("ErrorMessage_NotInRange_Amount");
                            }
                        }
                    }
                    break;
                case 'creditCardFirstName':
                    if (value == null || value.length === 0) {
                        fieldValidationErrors.push("ErrorMessage_Empty_FirstName");
                    } else if (value != null && value.length < FIRSTNAME_LASTNAME_MINIMUM_CHARS_NBR) {
                        fieldValidationErrors.push("ErrorMessage_MinimumChars_Name");
                    } else if (value != null && !(/^\D+$/.test(value))) {
                        fieldValidationErrors.push("ErrorMessage_NoNumber_Name");
                    }
                    break;
                case 'creditCardLastName':
                    if (value == null || value.length === 0) {
                        fieldValidationErrors.push("ErrorMessage_Empty_LastName");
                    } else if (value != null && value.length < FIRSTNAME_LASTNAME_MINIMUM_CHARS_NBR) {
                        fieldValidationErrors.push("ErrorMessage_MinimumChars_Name");
                    } else if (value != null && !(/^\D+$/.test(value))) {
                        fieldValidationErrors.push("ErrorMessage_NoNumber_Name");
                    }
                    break;

                case 'creditCardCompanyName':
                    if (value !== null && value.length <= 0) {
                        fieldValidationErrors.push("ErrorMessage_CreditCardCompanyName_Empty");
                    }
                    break;
                case 'addressCity':
                case 'addressProvince':
                case 'addressPostalCode':
                case 'addressCountry':
                    if (value == null || value.length === 0) {
                        fieldValidationErrors.push("ErrorMessage_Empty_" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1));
                    }
                    break;
                default:
                    break;
            }

            if (firstValidation) {
                if (typeof this.fieldValidatedAtLeastOnceOnBlur === 'undefined') {
                    this.fieldValidatedAtLeastOnceOnBlur = [];
                }
                this.fieldValidatedAtLeastOnceOnBlur.push(fieldName);
            }
        }


        return { valid: fieldValidationErrors.length === 0, fieldValidationErrors: fieldValidationErrors };
    }

    /**
     * Validatate authorized credit card company identified with the cardFirstDigits
     * @param {*} cardFirstDigits - The card first 4 digits
     */
    isValidCreditCardCompany(cardFirstDigits) {
        let authorizedCreditCardCompanyComaSeparated = FindFormSettingService.findSetting("AuthorizedCreditCardCompanies");
        if (authorizedCreditCardCompanyComaSeparated) {
            let authorizedCreditCardCompany = authorizedCreditCardCompanyComaSeparated.split(",");

            for (let i = 0; i < authorizedCreditCardCompany.length; i++) {
                if (CreditCardCompanies.validateCardDigitsForCompany(cardFirstDigits, authorizedCreditCardCompany[i])) {
                    return true;
                }
            }
        }
        return false;
    }

    async storeForm(formData) {
        await this.setState({
            storedForm: formData
        })
        //console.log('saved', formData)
    }

    setCardInformationAccessKey(cardInformationAccessKey) {
        this.cardInformationAccessKey = cardInformationAccessKey;
    }


    getCardInformationAccessKey() {
        return this.cardInformationAccessKey;
    }

    setMaskPAN(maskedPAN) {
        this.maskedPAN = maskedPAN;
    }

    getMaskPAN() {
        return this.maskedPAN;
    }

    hasStoredForm() {
        return Object.entries(this.state).length !== 0
    }

    getStoredForm() {
        return this.state.storedForm;
    }

    setConfirmationSuccess(isSuccess = true) {
        this.confirmationSuccess = isSuccess;
    }

    getConfirmationSuccess() {
        return this.confirmationSuccess || false;
    }

    setConfirmationNumber(refNumber) {
        this.refNumber = refNumber;
    }

    getConfirmationNumber() {
        return this.refNumber;
    }

    /**
     * Slice the last 4 digit of the card masked pan
     */
    getCreditCardLastDigits() {
        if (this.getMaskPAN()) {
            return this.getMaskPAN().slice(-4);
        } else {
            return DEFAULT_MASK_PAN;
        }
    }
    /**
     * Slice the first 4 digit of the card masked pan
     */
    getCreditCardFirstDigits() {
        if (this.getMaskPAN()) {
            return this.getMaskPAN().slice(0, 4);
        } else {
            return DEFAULT_MASK_PAN;
        }
    }


    async clearStoredForm() {
        await this.setState({
            storedForm: {}
        })
    }

}

// Following the Singleton Service pattern (think Angular Service),
// we will instantiate the Container from within this module
const FormService = new FormServiceContainer();

// Then we will wrap the provider and subscriber inside of functional
// React components. This simplifies the resuse of the module as we
// will be able to import this module as a depenency without having
// to import Unstated and/or create React Contexts  manually in the
// places that we want to Provide/Subscribe to the FormService Service.
export const FormServiceProvider = props => {
    // We leave the injector flexible, so you can inject a new dependency
    // at any time, eg: snapshot testing
    return <Provider inject={props.inject || [FormService]}>{props.children}</Provider>;
};

export const FormServiceSubscribe = props => {
    // We also leave the subscribe "to" flexible, so you can have full
    // control over your subscripton from outside of the module
    return <Subscribe to={props.to || [FormService]}>{props.children}</Subscribe>;
};

export default FormService;