import React, { Component } from 'react';
import ConfirmationSection from '../../../sections/ConfirmationSection/ConfirmationSection';
import ExitPageButton from '../../../buttons/ExitPageButton/ExitPageButton';
import FormService from '../../../../services/FormService';
import Utils from '../../../../utils';
import FindLabelService from '../../../../services/FindLabelService';
import history from "../../../../history";
import StartupService from '../../../../services/StartupService';

class StepConfirmationOTP extends Component {

  constructor(props){
    super(props);

    this.returnToFormBeggining = this.returnToFormBeggining.bind(this);
  }

  returnToFormBeggining(){
    history.push(`/${StartupService.getProductOffer()}/payment`);
  }

  render() {

    return (
      <div className="step-three">
        <ConfirmationSection formVersion={'OTP'} isSuccess={FormService.getConfirmationSuccess()} referenceNumber={FormService.getConfirmationNumber()} />
        <ExitPageButton failureAction={this.returnToFormBeggining} isSuccess={FormService.getConfirmationSuccess()} />
      </div>
    );
  }
}
  
  
export default StepConfirmationOTP;
  