import React, { Component } from "react";
import FindLabelService from "../services/FindLabelService";
import CustomButton from '../components/buttons/CustomButton/CustomButton';
import { FormFields } from "./FormFields";
import FormService from "../services/FormService";
import { EmptyForm } from "./EmptyForm";
import { EmptyFormValidations } from "./EmptyFormValidations";
import { Observable } from "rxjs";
import LoadingScreenService from "../services/LoadingScreenService";
import StartupService from "../services/StartupService";
import { EventEmitter } from "events";
import { LOBProducts } from "./LOBProducts";
import LocaleService from "../services/LocaleService";

export abstract class EPaymentFormComponent extends Component<{}, FormFields> {
  public formValid: boolean = true;
  private validations: any;
  private responseCodeObservable: Observable<string>;
  public abstract FORM_ID;
  public eventEmitter: EventEmitter;
  public recaptchaRef: React.RefObject<any>;
  public currentTemporaryToken!: string;

  constructor(props) {
    super(props);
    if(FormService.hasStoredForm()){
      this.state = FormService.getStoredForm();
    } else {
      this.state = EmptyForm;
      //this.state = TestForm;

    }

    this.validations = EmptyFormValidations;
    this.eventEmitter = new EventEmitter();
    this.recaptchaRef = React.createRef();
    this.getForm = this.getForm.bind(this);
    this.getFormValidations = this.getFormValidations.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setCardVerificationFailed = this.setCardVerificationFailed.bind(this);
    this.handleReCaptchaChange = this.handleReCaptchaChange.bind(this);
    
    this.responseCodeObservable = new Observable(observer => {
      setTimeout( () => {
          observer.next(window['AK_iFrame_Data'].responseCode);
      }, 1000);
    });

  }

  public triggerReCaptchaChallenge(){
    this.recaptchaRef.current.execute();
  }

  public handleReCaptchaChange(recaptchaValue: string): void {
    this.createPermanentToken(recaptchaValue);
    this.recaptchaRef.current.reset();
  }

  public abstract createPermanentToken(recaptchaValue: string): void;

  /**
   * Handle iframe moneris response
   * @param code - Moneris iframe response code
   */
  public abstract manageResponseCode(code: string);

  /**
     * Will enable loading screen, save form fields and then submit Iframe value and subscribe to it's response with manageResponseCode function
     * @param e/www3.moneris.com/HPPtoken/index.
     */
    async onSubmit(e: any){
      e.preventDefault();
      e.stopPropagation();
      this.performAllValidation();
      if(this.isFormValid()){
        this.setCardVerificationFailed(false);
        await LoadingScreenService.enableLoadingScreen();
        await FormService.storeForm(this.state);
        window['AK_submitIFrame'](StartupService.getIFrameMonerisTokenUrl(LOBProducts.getOfferEnv(StartupService.getProductOffer())));
        this.responseCodeObservable.subscribe(
        value => this.manageResponseCode(value));
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }


    }

    performAllValidation(){
      let form = document.getElementById(this.FORM_ID);
      if(form != null){
        let inputs = form.getElementsByTagName("input");

        Array.prototype.slice.call(inputs).forEach(element => {
          if(element.id !== 'canada-poste-input'){
            let validationObj = FormService.validateField(element.name, element.value, true);
  
            this.validations = Object.assign(this.validations, {
              [element.name]: validationObj.fieldValidationErrors
            })
          }
        });

        this.addressValidationVerification();
        this.forceUpdate();
      }
    }

    addressValidationVerification(){
      if((this.validations.addressCity && this.validations.addressCity.length > 0) || 
        (this.validations.addressProvince && this.validations.addressProvince.length > 0) || 
        (this.validations.addressCountry && this.validations.addressCountry.length > 0) || 
        (this.validations.addressPostalCode && this.validations.addressPostalCode.length > 0)){
          this.openAddressPaneForVisibility();
        }
    }

    openAddressPaneForVisibility(){
      this.eventEmitter.emit('openAddressPaneForVisibility')
    }

    isFormValid(){
      let valid = true;

      for (var key in this.validations) {
        // skip loop if the property is from prototype
        if (!this.validations.hasOwnProperty(key)) continue;
    
        if(key !== 'cardVerificationFailed'){ //Card is verified on submit
          var validation = this.validations[key];

          if(validation != null && validation.length !== 0){
            valid = false;
          }
        }
      }

      this.formValid = valid;
      return valid;
    }

    setCardVerificationFailed(isFailed: boolean = true, errorType: any = "ErrorMessage_ErrorThrown"){
      this.validations = Object.assign(this.validations, {
        cardVerificationFailed: !isFailed ? [] : [errorType]
      })
      this.forceUpdate();
    }

    getForm(){
      return {
        fields: this.state,
        getValidations: this.getFormValidations
      }
    }

    getFormValidations(field){
      return this.validations[field];
    }

    async handleInputChange(event: any) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      let validationObj;

      switch(event.type){
        case 'blur':
          validationObj = FormService.validateField(name, value, true);
          break;
        default:
          validationObj = FormService.validateField(name, value);
          break;
      }
    
      this.validations = Object.assign(this.validations, {
        [name]: validationObj.fieldValidationErrors
      })
      
      await this.setState(Object.assign(this.state, {
        [name]: value
      }));      
    }
    

}