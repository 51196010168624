import React from "react";
import { Provider, Subscribe, Container } from "unstated";
import StartupService from "./StartupService";
import { environment } from "../environments/environment"
import axios from 'axios';
import EventEmitter from 'events'
import StaticLocaleData from '../locale/staticLocalObj.json';
import { LOBProducts } from "../classes/LOBProducts";
import { FakeLocale } from "../classes/tests/fakeLocale";

const LANGUAGE_STORAGE_KEY = "language"
const LABELS_ROUTE_PREFIX = "parameters/labels/";

// Create a Container for our React Context. This container will
// hold state and methods just like a react component would:
export class LocaleServiceContainer extends Container {

  constructor() {
    super();

    // The state will be available to any component we inject
    // the Container instance into
    this.state = {
      localeObject: {},
      isLoaded: false,
      bus: new EventEmitter(),
      eventEmitter: new EventEmitter()
    };
  }

  /********************************************************************************
   * These methods will also be avaiable anywhere we inject our container context
   ********************************************************************************/
  //Load locale data
  async loadLocale() {
    if(!environment.loadDummyData){
      this.loadData();
    } else {
      // If loadDummyData flag is on, we load dummy data instead
      this.loadDummyData();
    }
  }

  //Await for unlocked message that will occurs once locale are loaded, then call $callback
  async waitForLocale() {
    if (!this.state.isLoaded) await new Promise(resolve => this.state.bus.once('unlocked', resolve));
  }

  //Load locale data from API
  async loadData(){
    console.log("Loading locale from API");
    var url = StartupService.getApiUrl() + LABELS_ROUTE_PREFIX + LOBProducts.getProductCombinaisonForOffer(StartupService.getProductOffer());
    
    axios.get(url)
    .then((response) => {
      this.handleLoadLocaleFinished(response.data.Content);
      if(typeof response.data.Maintenance !== 'undefined' && response.data.Maintenance != null){
        StartupService.enableMaintenance(response.data.Maintenance);
      }
    })
    .catch(() => this.handleLoadLocaleFailed());

  }

  loadTestData(){
    let data = FakeLocale;
    this.handleLoadLocaleFinished(data);
  }

  //Load locale data from hardcoded file (dev purpose)
  async loadDummyData(){
    console.log("Loading locale from Static Dummy Data");
    axios.get(environment.dummyLocaleObjUrl)
    .then((response) => {
      this.handleLoadLocaleFinished(response.data.Content);
    })
    .catch(() => this.handleLoadLocaleFailed());
  }

  async loadStaticData(){
    await this.setState({ localeObject:  Object.assign([], StaticLocaleData)});
    this.state.bus.emit('unlocked');
  }
  
  async handleLoadLocaleFailed(){
    console.error("Could not load locales");
    StartupService.getEventEmitter().emit('loading-error');
    await this.setState({ localeObject:  Object.assign([], StaticLocaleData)});
    this.state.bus.emit('unlocked');
  }

  async handleLoadLocaleFinished(data){
    console.log("Successfully loaded locales");
    await this.setState({ localeObject:  Object.assign([], StaticLocaleData.concat(data))});
    this.state.bus.emit('unlocked');
  }

  getLocale() {
    return Object.assign([], this.state.localeObject);
  }

  async setLanguage(newLanguage) {
    this.state.language = newLanguage;
    localStorage.setItem(LANGUAGE_STORAGE_KEY, this.state.language);
  }

  getLanguageFromUrl() {
    let splittedUrl = window.location.href.split("#");
    
    if(splittedUrl != null && splittedUrl.length > 1){
      let language =  splittedUrl[splittedUrl.length-1].toLowerCase();
      if(language === 'fr' || language === 'en'){
        this.setLanguage(language);
        return language;
      }
    }

    return null;
  }

  getLanguage() {
    return this.state.language || this.getLanguageFromUrl() || localStorage.getItem(this.LANGUAGE_STORAGE_KEY) || "fr";
  }

  getEventEmitter(){
    return this.state.eventEmitter;
  }

}

// Following the Singleton Service pattern (think Angular Service),
// we will instantiate the Container from within this module
const LocaleService = new LocaleServiceContainer();

// Then we will wrap the provider and subscriber inside of functional
// React components. This simplifies the resuse of the module as we
// will be able to import this module as a depenency without having
// to import Unstated and/or create React Contexts  manually in the
// places that we want to Provide/Subscribe to the LocaleService Service.
export const LocaleServiceProvider = props => {
  // We leave the injector flexible, so you can inject a new dependency
  // at any time, eg: snapshot testing
  return <Provider inject={props.inject || [LocaleService]}>{props.children}</Provider>;
};

export const LocaleServiceSubscribe = props => {
  // We also leave the subscribe "to" flexible, so you can have full
  // control over your subscripton from outside of the module
  return <Subscribe to={props.to || [LocaleService]}>{props.children}</Subscribe>;
};

export default LocaleService;

