export const EmptyFormValidations = {
    gpn: [],
    memberId: [],
    firstName: [],
    lastName: [],
    totalPremium: [],
    creditCardFirstName: [],
    creditCardLastName: [],
    creditCardCompanyName: [],
    addressNumber: [],
    addressStreet: [],
    addressCity: [],
    addressProvince: [],
    addressCountry: [],
    addressPostalCode: [],
    addressAppartment: [],
    addressPostalBox: [],
    addressBranch: [],
    addressRuralRoad: []
}; 