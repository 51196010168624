import React from "react";
import { Provider, Subscribe, Container } from "unstated";
import { environment } from "../environments/environment";
import LocaleService from './LocaleService';
import FormSettingsService from './FormSettingsService';
import axios from 'axios';
import MonerisService from "./MonerisService";
import EventEmitter from 'events';

// Create a Container for our React Context. This container will
// hold state and methods just like a react component would:
export class StartupServiceContainer extends Container {

  constructor() {
    super();

    // The state will be available to any component we inject
    // the Container instance into
    this.state = {
      isLoaded: false,
      apiUrl: "",
      bus: new EventEmitter()
    };
  }

  /********************************************************************************
   * These methods will also be avaiable anywhere we inject our container context
   ********************************************************************************/
  //Load config.json
  async loadConfig($callback) {
    console.log("Loading config");
    axios.get(environment.configFilePath)
    .then((response) => {
      this.handleLoadConfigFinished(response.data);
    })
    .catch(() => this.handleLoadConfigFailed());

    if (!this.state.isLoaded) await new Promise(resolve => this.state.bus.once('unlocked', resolve));
    $callback();
  }

  async afterCompleteStartup($callback){
    await Promise.all([LocaleService.waitForLocale(), FormSettingsService.waitForSettings(), MonerisService.waitForInformations()]);
    $callback();
  }

  async afterColdStartup($callback){
    await Promise.all([LocaleService.waitForLocale()]);
    $callback();
  }

  //Set config variables
  async handleLoadConfigFinished(data){
    await this.setState({ 
      apiUrl: process.env.FORCED_API_URL || data.apiUrl,
      iFrameMonerisTokenUrlProd: data.iFrameMonerisTokenUrlProd,
      iFrameMonerisTokenUrlDev: data.iFrameMonerisTokenUrlDev
    });
    this.state.bus.emit('unlocked');
    console.log("Successfully loaded config");
  }

  async handleLoadConfigFailed(){
    console.error("Could not load config");
    this.state.bus.emit('unlocked');
  }

  enableMaintenance(maintenance){
    if(typeof this.maintenance === 'undefined' || this.maintenance === null){
      this.maintenance = maintenance;
      StartupService.getEventEmitter().emit('scheduled-maintenance');
    }
  }

  getMaintenance(){
    return this.maintenance;
  }

  setProductOffer(productOffer){
    this.productOffer = productOffer;
  }
  
  getProductOffer(){
    return this.productOffer;
  }

  getAppBaseUrl(){
    return window.location.protocol + '//' + window.location.host + "/";
  }

  getApiUrl(){
    return this.state.apiUrl;
  }

  async setApiUrl(apiUrl){
    await this.setState(Object.assign(this.state, {apiUrl: apiUrl}));
  }
  
  getIFrameMonerisTokenUrl(env){
    if(typeof env !== 'undefined')
      switch(env.toLowerCase()){
        case 'prod':
          return this.state.iFrameMonerisTokenUrlProd;
        default:
          return this.state.iFrameMonerisTokenUrlDev;
      }
  }

  
  getEventEmitter(){
    return this.state.bus;
  }
}

// Following the Singleton Service pattern (think Angular Service),
// we will instantiate the Container from within this module
const StartupService = new StartupServiceContainer();

// Then we will wrap the provider and subscriber inside of functional
// React components. This simplifies the resuse of the module as we
// will be able to import this module as a depenency without having
// to import Unstated and/or create React Contexts  manually in the
// places that we want to Provide/Subscribe to the StartupService Service.
export const StartupServiceProvider = props => {
  // We leave the injector flexible, so you can inject a new dependency
  // at any time, eg: snapshot testing
  return <Provider inject={props.inject || [StartupService]}>{props.children}</Provider>;
};

export const StartupServiceSubscribe = props => {
  // We also leave the subscribe "to" flexible, so you can have full
  // control over your subscripton from outside of the module
  return <Subscribe to={props.to || [StartupService]}>{props.children}</Subscribe>;
};

export default StartupService;