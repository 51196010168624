/**
 * Take the subscriber object on its plain format, and return the adress formated line per line in an array
 * @param  {object} cpo the infromation returned by BC
 * @return {object} Return only the information we actually used in a more convenient format
 */
export const transformCPO = (cpo) => ({
    number: cpo.BuildingNumber,
    street: cpo.Street,
    extensionType: cpo.Type,
    extensionValue: cpo.SubBuilding,
    city: cpo.City,
    provinceCode: cpo.ProvinceCode,
    provinceName: cpo.ProvinceName,
    countryCode: cpo.CountryIso2,
    countryName: cpo.CountryName,
    postalCode: cpo.PostalCode,
    poBox: cpo.POBoxNumber,
  });
  
  export const getLanguageItem = (json, language) => {
    const items = json.Items;
    let lang;
    switch (language) {
      case 'fr':
        lang = 'FRE';
        break;
      case 'en':
      default:
        lang = 'ENG';
        break;
    }
    const filtered = items.filter((item) => item.Language === lang);
    return filtered[0];
  };
  
  /* Available fields provided by the CanadaPost object
  AdminAreaName
  Barcode
  Block
  BuildingName
  BuildingNumber
  City
  Company
  CountryIso2
  CountryIso3
  CountryIsoNumber
  CountryName
  DataLevel
  Department
  District
  DomesticId
  Field1
  Field2
  Field3
  Field4
  Field5
  Field6
  Field7
  Field8
  Field9
  Field10
  Field11
  Field12
  Field13
  Field14
  Field15
  Field16
  Field17
  Field18
  Field19
  Field20
  Id
  Label
  Language
  LanguageAlternatives
  Line1
  Line2
  Line3
  Line4
  Line5
  Neighbourhood
  POBoxNumber
  PostalCode
  Province
  ProvinceCode
  ProvinceName
  SecondaryStreet
  SortingNumber1
  SortingNumber2
  Street
  SubBuilding
  Type
  */
  