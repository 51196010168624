import React, { Component } from 'react';
import './ContactUs.scss'
import FindLabelService from '../../../services/FindLabelService'

class ContactUs extends Component {

  GetOpenHours() {
    let contact = FindLabelService.findLabel("ContactUs_OpenHours");
    if (contact === "ContactUs_OpenHours") {
      contact = "";
    }
    return contact;
  }

  GetPhoneNumber() {
    let contact = FindLabelService.findLabel("ContactUs_PhoneNumber");
    if (contact === "ContactUs_PhoneNumber") {
      return false;
    }
    return true;
  }

  render() {
    var phoneNumber;
    if (this.GetPhoneNumber()) {
      phoneNumber = <div className="row-phone"><a className="action-call" href={'tel:' + FindLabelService.findLabel("ContactUs_PhoneNumber")}><img src={process.env.PUBLIC_URL + '/assets/images/telephone.svg'} alt="telephone" /><span className="phone-number">{FindLabelService.findLabel("ContactUs_PhoneNumber")}</span></a></div>
    } else {
      phoneNumber = <div />;
    }

    return (
      <div className="contact-us">
        <div className="contact-us-wrapper">
          <h2>{FindLabelService.findLabel("contactSectionTitle")}</h2>
          <div className="time-frame">{this.GetOpenHours()}</div>
          <div className="row-send-mail"><a className="send-mail" href={'mailto:' + FindLabelService.findLabel("ContactUs_Email")}><img className="envelope" src={process.env.PUBLIC_URL + '/assets/images/mail_outline.svg'} alt="mail" /><span className="mail-text">{FindLabelService.findLabel("contactSectionSendEmailMessage")}</span><img className="arrow-img" src={process.env.PUBLIC_URL + '/assets/images/arrow.svg'} alt="arrow" /></a></div>
          {phoneNumber}
        </div>
      </div>
    );
  }
}


export default ContactUs;
