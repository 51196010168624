import React, { Component } from 'react';
import ReviewFormFieldsSection from '../../../sections/ReviewFormFieldsSection/ReviewFormFieldsSection';
import FormAgreementSection from '../../../sections/FormAgreementSection/FormAgreementSection';
import PreviousPageButton from '../../../buttons/PreviousPageButton/PreviousPageButton';
import SubmitFormButton from '../../../buttons/SubmitFormButton/SubmitFormButton';
import history from "../../../../history";
import FormService from '../../../../services/FormService';
import { SessionExpireComponent } from '../../../../classes/SessionExpireComponent';
import StartupService from '../../../../services/StartupService';
import { UpdateCCRequest } from '../../../../classes/UpdateCCRequest';
import axios from 'axios';
import { LOBProducts } from '../../../../classes/LOBProducts';
import LoadingScreenService from '../../../../services/LoadingScreenService';
import FindLabelService from '../../../../services/FindLabelService';


class StepReviewUpdateCC extends SessionExpireComponent {

  SESSION_TIMEOUT_TIME = (15 * 60 * 1000);
  popupButtonAction = this.navigateBack = this.navigateBack.bind(this);
  UPDATE_CC_ROUTE = "update-cc"

  private agreementChecked: boolean = false;
  private agreementUncheckedError: boolean = false;


  constructor(props){
    super(props);

    if(!FormService.hasStoredForm()){
      //window.location.hash = `/${StartupService.getProductOffer()}/update-cc`;
      history.push(`/${StartupService.getProductOffer()}/update-cc`);
    }
   

   this.submitForm = this.submitForm.bind(this);
   this.updateAgreementCheckbox = this.updateAgreementCheckbox.bind(this);
    this.initExpirationPopup()
  }

    /**
     * Will enable loading screen and execute Update Credit Card action
     */
    async submitForm(){
      if(this.agreementChecked){
        this.agreementUncheckedError = false;
        this.forceUpdate();
        await LoadingScreenService.enableLoadingScreen();
        this.executeUpdateCC();
      }
      else {
        this.agreementUncheckedError = true;
        this.forceUpdate();
      }
    }

    async updateAgreementCheckbox(event){
      this.agreementChecked = event.target.checked;
    }

    /**
     * Query backend to Update Credit Card information
     */
    executeUpdateCC(){
      var url = StartupService.getApiUrl() + this.UPDATE_CC_ROUTE;
   
          axios.post(url, new UpdateCCRequest(
            LOBProducts.getProductObjectForOffer(StartupService.getProductOffer()).lineOfBusinessCode, 
            LOBProducts.getProductObjectForOffer(StartupService.getProductOffer()).productCode, 
            StartupService.getProductOffer(),
            this.agreementChecked,
            FormService.getCardInformationAccessKey(), 
            FormService.getStoredForm()))
          .then((response) => {
            if(response.data.Status === "000"){
              FormService.setConfirmationSuccess();
            } else {
              FormService.setConfirmationSuccess(false);
            }

            this.clearSessionTimer();

            //Clear form after us
            FormService.clearStoredForm();
            
            //window.location.hash = `/${StartupService.getProductOffer()}/update-cc/confirmation`;
            history.push(`/${StartupService.getProductOffer()}/update-cc/confirmation`);
            window.scrollTo({top: 0, behavior: 'smooth'});
            LoadingScreenService.disableLoadingScreen();
          })
          .catch(() => {
            FormService.setConfirmationSuccess(false);
            //window.location.hash = `/${StartupService.getProductOffer()}/update-cc/confirmation`;
            history.push(`/${StartupService.getProductOffer()}/update-cc/confirmation`);
            window.scrollTo({top: 0, behavior: 'smooth'});
            LoadingScreenService.disableLoadingScreen();
          });
    }

    navigateBack(){
      this.clearSessionTimer();
      //window.location.hash = `/${StartupService.getProductOffer()}/update-cc`;
      history.push(`/${StartupService.getProductOffer()}/update-cc`);
    }

    getAgreementErrorCompoenent(){
      if(this.agreementUncheckedError){
        return (
          <span className="error info-erreur icone-warning big-margin-bottom" key={'validation-agreement'}>{FindLabelService.findLabel('ErrorMessage_AgreementNotChecked')}</span>
        )
      } else {
        return "";
      }
    }

    render() {
  
      return (
        <div className="step-two">
          {this.getSessionTimeoutPopup()}
          <ReviewFormFieldsSection creditCardLastDigits={FormService.getCreditCardLastDigits()}/>
          <FormAgreementSection withConsent={true} consentAction={this.updateAgreementCheckbox} creditCardLastDigits={FormService.getCreditCardLastDigits()} />
          {this.getAgreementErrorCompoenent()}
          <div className="btn-container">
            <PreviousPageButton previousAction={this.navigateBack} />
            <SubmitFormButton submitAction={this.submitForm} />
          </div>
        </div>
      );
    }
  }
  
  
export default StepReviewUpdateCC;
  