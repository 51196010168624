import React, { Component } from 'react';
import './SubmitFormButton.scss'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import FindLabelService from '../../../services/FindLabelService'

interface  SubmitFormButtonProps {
  submitAction: any;
  className?: string;
  disabled?: boolean;
}

interface SubmitFormButtonState{
  disabled?: boolean;
}

class SubmitFormButton extends Component<SubmitFormButtonProps, SubmitFormButtonState> {
  private submitAction: any;
  private className: string;

    constructor(props: any) {
      super(props);
  
      this.submitAction = props.submitAction;
      this.className = props.className || "";
      this.state = { disabled : (props.disabled || false) };

      this.setDisable = this.setDisable.bind(this);
    }

    setDisable(){
      this.submitAction();
    }
  
    render() {
      return (
        <button disabled={this.state.disabled} id="submit-form-button" className={"submit-form-button " + this.className} onClick={this.setDisable}>
          {FindLabelService.findLabel("btnSubmitLabel")}
        </button>
      );
    }
  }
  
  
export default SubmitFormButton;
  