import React, { Component } from 'react';
import './PageMaintenance.scss';
import FindLabelService from '../../services/FindLabelService'
import StartupService from '../../services/StartupService';
import Utils from '../../utils';
import LocaleService from '../../services/LocaleService';

class PageMaintenance extends Component {


  buildPage(){
    let elementList: any[] = [];

    let maintenance = StartupService.getMaintenance();

    elementList.push(<h3 key={Utils.getElementListUniqueKey('maintenance-header')}>{FindLabelService.findLabel('Page_Maintenance_Title')}</h3>)
    elementList.push(<p key={Utils.getElementListUniqueKey('maintenance-msg')} >{FindLabelService.findLabel('Page_Maintenance_Message_Line1_Part1')}{Utils.isNullOrUndefined(maintenance.EndDate) ? '' : ` ${FindLabelService.findLabel('Page_Maintenance_Message_Line1_Part2')} ${Utils.formatDateString(maintenance.EndDate, LocaleService.getLanguage())}`}.
    <br />{FindLabelService.findLabel('Page_Maintenance_Message_Line2')}</p>)
    elementList.push(<p key={Utils.getElementListUniqueKey('maintenance-msg')}><strong>{FindLabelService.findLabel('Page_Maintenance_ThankYou')}</strong></p>)

    let maintenanceMsg = Utils.getMaintenanceMessage(maintenance, LocaleService.getLanguage());
    if(maintenanceMsg !== null){
      elementList.push(<pre key={Utils.getElementListUniqueKey('maintenance-detail')}>{FindLabelService.findLabel('Page_Maintenance_Detail')}{maintenanceMsg}</pre>)
    }
    return <div className="page-maintenance" key={Utils.getElementListUniqueKey('maintenance-container')}>{elementList.map((element) => {
      return element
    })}</div>
  }

  render() {
    return this.buildPage();
  }
}


export default PageMaintenance;
