import { FormFields } from "./FormFields";
import { ContractHolderInfo } from "./ContractHolderInfo";
import { CcInfo } from "./CcInfo";
import { LOBProduct } from "./LOBProduct";

export class LOBProducts {
    
    /**
     * List of valid LOB - Product - Offer combinaison
     */
    public static LOBProductsList : LOBProduct[] = [
        {
            offer: "AcciFamille",
            lineOfBusinessCode: "AI",
            productCode: "AcciFamille",
            validForms: ["update-cc"],
            env: 'prod'
        },
        {
            offer: "LifeInsurance",
            lineOfBusinessCode: "AI",
            productCode: "LifeInsurance",
            validForms: ["update-cc"],
            env: 'prod'
        },
        {
            offer: "SMS",
            lineOfBusinessCode: "ERC",
            productCode: "SMS",
            validForms: ["otp","update-cc"],
            env: 'prod'
        },
        {
            offer: "SISIP",
            lineOfBusinessCode: "ERC",
            productCode: "SMS",
            validForms: ["otp", "update-cc"],
            env: 'prod'
        },
        {
            offer: "MRA",
            lineOfBusinessCode: "AI",
            productCode: "MRA",
            validForms: ["otp"],
            env: 'prod'
        },
        {
            offer: "Test1",
            lineOfBusinessCode: "EPAY",
            productCode: "Product01",
            validForms: ["otp", 'update-cc'],
            env: 'dev'
        },
        {   
            offer: "Investia",
            lineOfBusinessCode: "INV",
            productCode: "InvestiaAdvisors",
            validForms: ['update-cc'],
            env: 'prod'
        },
        {
            offer: "InvestiaClient",
            lineOfBusinessCode: "INVC",
            productCode: "InvestiaAdvisorsClient",
            validForms: ['otp'],
            env: 'prod'
        },
        {
            offer: "NRD",
            lineOfBusinessCode: "INV",
            productCode: "InvestiaAdvisors",
            validForms: ["update-cc"],
            env: 'prod'
        },
        {
            offer: "ENO",
            lineOfBusinessCode: "INV",
            productCode: "InvestiaAdvisors",
            validForms: ["update-cc"],
            env: 'prod'
        },
        {
            offer: "ClubSocialQC",
            lineOfBusinessCode: "IACS",
            productCode: "ClubSocialQC",
            validForms: ["otp"],
            env: 'prod'
        },
        {
            offer: "DealerServices",
            lineOfBusinessCode: "IADS",
            productCode: "DealerServices",
            validForms: ["otp"],
            env: 'prod'
        },
        {
            offer: "DealerServicesHyundai",
            lineOfBusinessCode: "IADSH",
            productCode: "DealerServicesHyundai",
            validForms: ["otp"],
            env: 'prod'
        },
        {
            offer: "PQAP",
            lineOfBusinessCode: "IA",
            productCode: "PQAP",
            validForms: ["otp"],
            env: 'prod'
        }
    ]

    public static getOfferEnv(offer){
        let productObj = LOBProducts.getProductObjectForOffer(offer);
        return productObj.env;
    }

    /**
     * Validate that a given offer has a given form
     * @param offer - The offer to lookup
     * @param form - The form to lookup
     */
    public static isValidForm(offer, form){
        let productObj = LOBProducts.LOBProductsList.find((productObj) => {
            return productObj.offer.toLowerCase() === offer.toLowerCase();
        })

        if(productObj){
            return productObj.validForms.indexOf(form) !== -1;
        }
        return false;
    }

    /**
     * Validate that a given offer exist inside LOBProductsList
     * @param offer - The offer to lookup
     */
    public static isValidOffer(offer){
        let productObj = LOBProducts.LOBProductsList.find((productObj) => {
            return productObj.offer.toLowerCase() === offer.toLowerCase();
        })

        return productObj ? true : false;
    }

    /**
     * Will return the combinaison string for the given offer
     * @param offer - The offer to lookup
     */
    public static getProductCombinaisonForOffer(offer): string{
        let productObj = LOBProducts.getProductObjectForOffer(offer);
        return `${productObj.lineOfBusinessCode}--${productObj.productCode}--${productObj.offer}`
    }

    /**
     * Will return the product object for the given offer
     * @param offer - The offer to lookup
     */
    public static  getProductObjectForOffer(offer) : LOBProduct {
        let productObj = LOBProducts.LOBProductsList.find((productObj) => {
            return productObj.offer.toLowerCase() === offer.toLowerCase();
        })

        if(productObj){
            return productObj;
        }

        return {
            offer: "",
            lineOfBusinessCode: "",
            productCode: "",
            validForms: [],
            env: ''
        };
    }
}

