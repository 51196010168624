import FindFormSettingService from "./services/FindFormSettingService";
import React, { Component } from 'react';
import LocaleService from "./services/LocaleService";

export class Utils{
    static getElementListUniqueKey(prefixIdentifier): string{
        return `${prefixIdentifier}-${Math.round(Math.random()*10000)}`
    }

    static getRedirectionUrl(s){

        var prefix1 = 'https://';
        var prefix2 = 'http://';
        if (s && s.substr(0, prefix1.length) !== prefix1 && s.substr(0, prefix2.length) !== prefix2)
        {
            s = prefix1 + s;
        }
        return s;
    }

    static getMaintenanceMessage(maintenanceObj, lang){
      if(lang === 'en' && maintenanceObj.DescriptionEN){
        return maintenanceObj.DescriptionEN
      } else if(lang === 'fr' && maintenanceObj.DescriptionFR){
        return maintenanceObj.DescriptionFR
      } else {
        return null;
      }
    }

    static isNullOrUndefined(variable){
      return !(typeof variable !== 'undefined' && variable != null);
    }

    static formatDateString(dateString, lang) {
      if(typeof dateString !== 'undefined' && dateString != null){
        let date = new Date(dateString);
        let monthNames: string[] = [];
  
        if(lang === 'en'){
          monthNames = [ 
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
          ];
        } else {
          monthNames = [
            "Janvier", "Février", "Mars",
            "Avril", "Mai", "Juin", "Juillet",
            "Août", "Septembre", "Octobre",
            "Novembre", "Décembre"]
        }
      
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var hours = date.getHours();
        var minutes = date.getMinutes();
      
        if(lang === 'en'){
  
          var isPM = false;
          if(hours>=12){
            isPM = true;
  
            if(hours >= 13)
              hours -= 12;
          }
  
          return monthNames[monthIndex] + ' ' + day + ', ' + hours +  ':' + ('0' + minutes).slice(-2) + (isPM ? ' PM' : ' AM') + ' eastern time';
        } else {
          return day + ' ' + monthNames[monthIndex] + ', ' + ('0' + hours).slice(-2) +  'h' + ('0' + minutes).slice(-2) + ' heure de l\'est';
        }
      }
      else {
        return lang === 'en' ? 'undefined' : 'indéfini'
      }
    }
    
    // Return a fully formated two row address
    static getFullAddress(addressCity, addressProvince, addressCountry, addressPostalCode, 
        addressNumber?, addressStreet?, addressAppartment?,
        addressPostalBox?, addressBranch?, addressRuralRoad?){
        
        let rows: any[] = [];
  
        let firstRow = ``;
  
        if (addressNumber){
          firstRow += `${addressNumber} `;
        }
        if (addressStreet){
          if (firstRow.length > 0) firstRow += ` `;
          firstRow += `${addressStreet}`;
        }
        if(addressAppartment){
          if (firstRow.length > 0) firstRow += `, `;
          firstRow += `App/Suite ${addressAppartment}`;
        }
  
        if(firstRow !== ''){
          rows.push(firstRow);
        }
  
        let secondRow = ``;
  
        if (addressPostalBox){
          if (secondRow.length > 0) secondRow += `, `;
          secondRow += `${LocaleService.getLanguage() === 'en' ? 'PO BOX' : 'CP'} ${addressPostalBox}`;
        }
        if (addressBranch){
          if (secondRow.length > 0) secondRow += `, `;
          secondRow += `${LocaleService.getLanguage() === 'en' ? 'STN' : 'SUCC'} ${addressBranch}`;
        }
        if(addressRuralRoad){
          if (secondRow.length > 0) secondRow += `, `;
          secondRow += `${LocaleService.getLanguage() === 'en' ? 'RR' : 'RR'} ${addressRuralRoad}`;
        }
        
        if(secondRow !== ''){
          if(firstRow !== ''){
            rows.push(<br key={'second-row-br'} />);
          }
          rows.push(secondRow);
        }
  
        let thirdRow = ``;
        
        if(addressCity){
          thirdRow += `${addressCity}`;
        }
        if(addressProvince){
          if (thirdRow.length > 0) thirdRow += `, `;
          thirdRow += `${addressProvince}`;
        }
        if(addressCountry){
          if (thirdRow.length > 0) thirdRow += `, `;
          thirdRow += `${addressCountry}`;
        }
        if(addressPostalCode){
          if (thirdRow.length > 0) thirdRow += ` `;
          thirdRow += `${addressPostalCode}`;
        }
        
        if(thirdRow !== ''){
          if(firstRow !== '' || firstRow !== ''){
            rows.push(<br key={'third-row-br'} />);
          }
          rows.push(thirdRow);
        }
       
        return (<span id="review-full-address" key={'address-lines'}>{rows.map((element) => {
              return element
          })}</span>);
      }
}

export default Utils;