import React, { Component } from 'react';
import FindLabelService from '../../services/FindLabelService'
import { Router as Router, Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import history from "../../history";
import StepFillFormUpdateCC from './FormSteps/StepOne/StepFillFormUpdateCC'
import StepReviewUpdateCC from './FormSteps/StepTwo/StepReviewUpdateCC'
import StepConfirmationUpdateCC from './FormSteps/StepThree/StepConfirmationUpdateCC';
import PageNotFound from '../error/PageNotFound';
import StartupService from '../../services/StartupService';
import { LOBProducts } from '../../classes/LOBProducts';


class FormUpdateCC extends Component<{}, {invalidForm: boolean}> {

  constructor(props: any) {
    super(props);

    if(LOBProducts.isValidForm(StartupService.getProductOffer(), 'update-cc')){
      this.state = {
        invalidForm: false
      }
    }
    else {
      this.state = {
        invalidForm: true
      }
    }
  }

  render() {
    if(this.state.invalidForm){
      return (
          <PageNotFound />
      )
    } else {
      return (
        <div className="form form-update-cc">
          <div className="pane-wrapper">
            <div className="left-side-pane">
              <div className="product-infos">
                <h1>{FindLabelService.findLabel("formUpdateCCTitle")}</h1>
                <h2>{FindLabelService.findLabel("UpdateCC_FormName")}</h2>
              </div>
            </div>
            <div className="right-side-pane">
              <Router history={history}>
                <Switch>
                  <Route path="/:LOBProduct/update-cc/review" component={StepReviewUpdateCC} />
                  <Route path="/:LOBProduct/update-cc/confirmation" component={StepConfirmationUpdateCC} />
                  <Route path="/:LOBProduct/update-cc" component={StepFillFormUpdateCC} />
                </Switch>
              </Router>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default FormUpdateCC;
