import React, { Component } from 'react';
/* import './style/Variables.scss';
import './style/Util.scss';
import './style/Dev.scss'; */
import './App.scss';
import { Router as Router, Route, Link, Switch, HashRouter } from 'react-router-dom';
import FindLabelService from './services/FindLabelService'
import LocaleService from './services/LocaleService';
import { environment } from "./environments/environment"
import FormUpdateCC from './components/FormUpdateCC/FormUpdateCC';
import FormOTP from './components/FormOTP/FormOTP';
import history from './history';
import Header from './components/layout/Header/Header'
import ContactUs from './components/layout/ContactUs/ContactUs';
import Footer from './components/layout/Footer/Footer';
import PageNotFound from './components/error/PageNotFound';
import PageServiceUnavailable from './components/error/PageServiceUnavailable';
import StartupService from './services/StartupService';
import FormSettingService from './services/FormSettingsService';
import MonerisService from './services/MonerisService';
import LoadingScreenService from './services/LoadingScreenService';
import { LOBProducts } from './classes/LOBProducts';
import FindFormSettingService from './services/FindFormSettingService';
import Utils from './utils';
import PageMonerisError from './components/error/PageMonerisError';
import PageMaintenance from './components/error/PageMaintenance';


class App extends Component<{match: any}, {isLoading: boolean, invalidOffer: boolean, loadingError: boolean, monerisError: boolean, scheduledMaintenance: boolean}> {
  constructor(props: any) {
    super(props);

    StartupService.setProductOffer(this.props.match.params.ProductOffer);
    if(LOBProducts.isValidOffer(StartupService.getProductOffer())){
      this.state = {
        isLoading: true,
        invalidOffer: false,
        loadingError: false,
        monerisError: false,
        scheduledMaintenance: false
      }
      this.initializationProcess();
    }
    else {
      this.state = {
        isLoading: true,
        invalidOffer: true,
        loadingError: false,
        monerisError: false,
        scheduledMaintenance: false
      }
      
      this.initializeColdStartup();
   
        
      //LoadingScreenService.disableLoadingScreen();
    }

    this.switchLanguage = this.switchLanguage.bind(this);
    this.handleLoadingError = this.handleLoadingError.bind(this);
    this.handleMonerisError = this.handleMonerisError.bind(this);
    this.handleMaintenanceMode = this.handleMaintenanceMode.bind(this);
    StartupService.getEventEmitter().on('loading-error', this.handleLoadingError);
    StartupService.getEventEmitter().on('moneris-error', this.handleMonerisError);
    StartupService.getEventEmitter().on('scheduled-maintenance', this.handleMaintenanceMode);

  }

  initializeColdStartup(){
    console.time("Startup"); //To keep track of the startup time
    StartupService.loadConfig(function(){
      LocaleService.loadStaticData();
    });

    StartupService.afterColdStartup(() => {
      console.timeEnd("Startup");
      this.setState({
        isLoading: false
      })
      LoadingScreenService.disableLoadingScreen();
      this.forceUpdate();
      console.log("Rendering App")

  })
  }

  initializationProcess(){
    console.time("Startup"); //To keep track of the startup time

    // Load environment config file
    StartupService.loadConfig(function(){
        
        // Load locales and form settings
        LocaleService.loadLocale();
        FormSettingService.loadSettings();
        MonerisService.loadMonerisInformations();
    });

    // Wait for locales and form settings to be loaded
    StartupService.afterCompleteStartup(() => {
        console.timeEnd("Startup");
        this.applyWhiteLabellingRules();
        this.setState({
          isLoading: false
        })
        this.forceUpdate();
        setTimeout(() => {
          
          LoadingScreenService.disableLoadingScreen();
        },100)
        console.log("Rendering App")

    })
  }

  applyWhiteLabellingRules(){
    let whiteLabelingEnabled = FindFormSettingService.findSetting("WhiteLabelingEnabled");

    if(!whiteLabelingEnabled){
        var link = document.createElement('link');
        link.rel = 'shortcut icon';
        link.href = process.env.PUBLIC_URL + '/assets/favicon.ico';
        let head = document.head || document.getElementsByTagName('head')[0];

        let dynamicFavicon = document.getElementById("dynamic-favicon");
        if(dynamicFavicon != null) { 
            head.removeChild(dynamicFavicon); 
        }
        head.appendChild(link);
        document.title = FindLabelService.findLabel("PageTitle");
    } else {
        document.title = FindLabelService.findLabel("CustomPageTitle");
    }
  }

  async switchLanguage() {

    switch(LocaleService.getLanguage()){
      case 'fr':
         LocaleService.setLanguage('en');
         break;
      case 'en':
        LocaleService.setLanguage('fr');
        break;
      default:
        LocaleService.setLanguage('en');
        break;
    }
    let whiteLabelingEnabled = FindFormSettingService.findSetting("WhiteLabelingEnabled");

    if(!whiteLabelingEnabled){
        document.title = FindLabelService.findLabel("PageTitle");
    } else {
        document.title = FindLabelService.findLabel("CustomPageTitle");
    }
    
    await LocaleService.getEventEmitter().emit('language-changed');
    
    this.forceUpdate();
  }

  handleLoadingError(){
    console.error('An error occured during application startup, displaying error message');
    this.setState({
      loadingError: true
    })
  }
  handleMonerisError(){
    console.error('An error occured while trying to connect to moneris, displaying error message');
    this.setState({
      monerisError: true
    })
  }
  handleMaintenanceMode(){
    console.warn('Maintenance schedule detected, displaying maintenance message');
    this.setState({
      scheduledMaintenance: true
    })
  }
  
  
  

  render() {
    if(this.state.isLoading){
      return (
        <div className="App">
        {LoadingScreenService.getLoadingScreenComponent()}
          Loading...
        </div>
      )
    } else if(this.state.invalidOffer){
      return (
        <div className="App">
          <Header switchLanguageAction={this.switchLanguage} />
          <PageNotFound />
        </div>
      )
    } else if(this.state.loadingError || this.state.monerisError){
      return (
        <div className="App">
          <Header switchLanguageAction={this.switchLanguage} />
          <PageServiceUnavailable />
        </div>
      )
    } else if(this.state.scheduledMaintenance){
      return (
        <div className="App">
          <Header switchLanguageAction={this.switchLanguage} />
          <PageMaintenance />
          <ContactUs />
          <Footer />
        </div>
      )
    } else {
      return (
        <div className="App">
          {LoadingScreenService.getLoadingScreenComponent()}
          <Header switchLanguageAction={this.switchLanguage} />
          <Router history={history}>
            <Switch>
              <Route path="/:LOBProduct/update-cc" component={FormUpdateCC} />
              <Route path="/:LOBProduct/payment" component={FormOTP} />
              <Route component={PageNotFound} />
            </Switch>
          </Router>
          <ContactUs />
          <Footer />
        </div>
      );
    }    
  }
}


export default App;
