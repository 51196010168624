import React, { Component } from 'react';
import './Header.scss'
import LocaleService from '../../../services/LocaleService';
import FindLabelService from '../../../services/FindLabelService'
import FindFormSettingService from '../../../services/FindFormSettingService';

interface  HeaderProps {
    switchLanguageAction: any;
}

class Header extends Component<HeaderProps> {
    
    private whiteLabelingEnabled: boolean;
    private switchLanguageAction: any;

    constructor(props: any) {
        super(props);
        this.whiteLabelingEnabled = FindFormSettingService.findSetting("WhiteLabelingEnabled");
  
        this.switchLanguageAction = props.switchLanguageAction;
    }
    
    getDesiredContent(){
        if(this.whiteLabelingEnabled){
            return(
                <header className="App-header">
                    <div className="App-header-wrapper">
                        <img src={FindFormSettingService.findSetting("WhiteLabelingLogo")}  className="App-logo" alt="logo" />
                        <button className="App-lang-btn" onClick={this.switchLanguageAction}>{LocaleService.getLanguage() === 'en' ? 'FR' : 'EN'}</button>
                    </div>
                </header>
            );
        } else {
            return (
                <header className="App-header">
                    <div className="App-header-wrapper">
                        <img src={process.env.PUBLIC_URL + (LocaleService.getLanguage() === 'en' ? "/assets/images/iAFG.svg" : "/assets/images/iAGF.svg")}  className="App-logo" alt="logo" />
                        <button className="App-lang-btn" onClick={this.switchLanguageAction}>{LocaleService.getLanguage() === 'en' ? 'FR' : 'EN'}</button>
                    </div>
                </header>
            );
        }
    } 

    render() {
        return this.getDesiredContent();
    }
}
  
  
export default Header;
  