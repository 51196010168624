import React, { Component } from 'react';
import FindLabelService from '../../../../services/FindLabelService'
import ConfirmationSection from '../../../sections/ConfirmationSection/ConfirmationSection';
import ExitPageButton from '../../../buttons/ExitPageButton/ExitPageButton';
import FindFormSettingService from '../../../../services/FindFormSettingService';
import history from "../../../../history";
import Utils from '../../../../utils';
import FormService from '../../../../services/FormService';
import StartupService from '../../../../services/StartupService';

class StepConfirmationUpdateCC extends Component {

  constructor(props){
    super(props);

    this.returnToFormBeggining = this.returnToFormBeggining.bind(this);
  }

  returnToFormBeggining(){
    history.push(`/${StartupService.getProductOffer()}/update-cc`);
  }

  render() {

    return (
      <div className="step-three">
        <ConfirmationSection isSuccess={FormService.getConfirmationSuccess()} formVersion={'UpdateCC'} />
        <ExitPageButton failureAction={this.returnToFormBeggining} isSuccess={FormService.getConfirmationSuccess()} />
      </div>
    );
  }
}
  
  
export default StepConfirmationUpdateCC;