import { FormFields } from "./FormFields";
import { ContractHolderInfo } from "./ContractHolderInfo";
import { CcInfo } from "./CcInfo";
import { CreditCardCompany } from "./CreditCardCompany";
import LocaleService from "../services/LocaleService";

export class CreditCardCompanies {
    
    /**
     * List of valid Credit Card Provider, with their valid first digits
     */
    public static CreditCardCompanyList : CreditCardCompany[] = [
        {
            companyCode: "VS",
            companyName: "Visa",
            validFirstDigits: [
                "4"
            ]
        },
        {
            companyCode: "MC",
            companyName: "MasterCard",
            validFirstDigits: [
                "51",
                "52",
                "53",
                "54",
                "55"
            ]
        },
        {
            companyCode: "AX",
            companyName: "American Express",
            validFirstDigits: [
                "34",
                "37"
            ]
        },
        {
            companyCode: "DR",
            companyName: "Discover",
            validFirstDigits: [
                "6011",
                "65"
            ]
        },
        {
            companyCode: "JB",
            companyName: "JCB",
            validFirstDigits: [
                "35"
            ]
        },
        {
            companyCode: "UP",
            companyName: "China Union Pay",
            validFirstDigits: [
                "62"
            ]
        }
    ]

    /**
     * Return a string enumarating the company names, for the given company ids
     * @param companyCodes - The coma separated list of companyCode
     */
    public static getCreditCardCompanyNameForCompanies(companyCodes){
        let companyEnumeration = "";

        let codes = companyCodes.split(",");

        for(let i = 0; i < codes.length; i++) {
            if(i === codes.length - 1){
                companyEnumeration += LocaleService.getLanguage() === 'en' ? " or " : ' ou ';
            } else if(i > 0){
                companyEnumeration += ", ";
            }
            companyEnumeration += CreditCardCompanies.getCompanyObject(codes[i]).companyName;
        }

        return companyEnumeration;
    }

    /**
     * Will validate the credit card first digit match the valid first digits for the given company
     * @param creditCardFirstDigits - The credit card first digits to validate
     * @param companyCode - The company code
     */
    public static validateCardDigitsForCompany(creditCardFirstDigits, companyCode): boolean{
        let validFirstDigits = CreditCardCompanies.getValidFirstCardDigitsForCompany(companyCode);

        for(let i = 0; i < validFirstDigits.length; i++){
            //If match one of the validFirstDigits, return valid/true
            if(creditCardFirstDigits.slice(0, validFirstDigits[i].length) === validFirstDigits[i]){
                return true;
            }
        }
        return false;
    }

     /**
     * Will return ValidFirstCardDigits array for the given company
     * @param companyCode - The company code
     */
    public static getValidFirstCardDigitsForCompany(companyCode): string[]{
        let companyObj = CreditCardCompanies.getCompanyObject(companyCode);
        return companyObj.validFirstDigits;
    }

    /**
     * Will return the comapy object for the given company id
     * @param companyCode - The company code
     */
    public static  getCompanyObject(companyCode) : CreditCardCompany {
        let companyObj = CreditCardCompanies.CreditCardCompanyList.find((creditCardCompanyObj) => {
            return creditCardCompanyObj.companyCode === companyCode;
        })

        if(companyObj){
            return companyObj;
        }

        return {
            companyCode: "",
            companyName: "",
            validFirstDigits: []
        };
    }
}

