import React from "react";
import { Provider, Subscribe, Container } from "unstated";
import StartupService from './StartupService';
import EventEmitter from 'events'
import axios from 'axios';
import LocaleService from "./LocaleService";
import { LOBProducts } from "../classes/LOBProducts";

const IFRAME_ROUTE_EN = "tokenization/iframe/EN/";
const IFRAME_ROUTE_SUFFIX = "/AKClient"
const IFRAME_ROUTE_FR = "tokenization/iframe/FR/";

// Create a Container for our React Context. This container will
// hold state and methods just like a react component would:
export class MonerisServiceContainer extends Container {

    constructor() {
        super();

        // The state will be available to any component we inject
        // the Container instance into
        this.state = {
            isLoaded: false,
            iframeUriFr: "",
            iframeUriEn: "",
            iframeJavascript: "",
            reCaptchaSiteKey: "",
            bus: new EventEmitter()
        };
    }

    /********************************************************************************
     * These methods will also be avaiable anywhere we inject our container context
     ********************************************************************************/

    /**
     * Will retrieve moneris iframe uri - FR & EN
     */
    loadMonerisInformations(){
        console.log("Loading moneris informations");

        let productobj = LOBProducts.getProductObjectForOffer(StartupService.getProductOffer());
        var urlEn = `${StartupService.getApiUrl()}${IFRAME_ROUTE_EN}${productobj.lineOfBusinessCode}/${productobj.productCode}${IFRAME_ROUTE_SUFFIX}`;

        axios.get(urlEn)
        .then((response) => {
            // TODO: handle multiple status codes
            this.handleLoadIframeEnFinished(response.data);
        })
        .catch(() => this.handleLoadMonerisInformationFailed());
        
        var urlFr = `${StartupService.getApiUrl()}${IFRAME_ROUTE_FR}${productobj.lineOfBusinessCode}/${productobj.productCode}${IFRAME_ROUTE_SUFFIX}`;

        axios.get(urlFr)
        .then((response) => {
            // TODO: handle multiple status codes
            this.handleLoadIframeFrFinished(response.data);
        })
        .catch(() => this.handleLoadMonerisInformationFailed());
    }

    async handleLoadIframeEnFinished(data){
        await this.setState({
            iframeUriEn: data.content.iFrame,
            iframeJavascript: data.content.javascript,
            reCaptchaSiteKey: data.content.reCaptchaSiteKey || ""
        })
        console.log("Successfully loaded moneris - Iframe EN");
    
        if(this.state.iframeUriFr !== ''){
            this.state.bus.emit('unlocked');
        }
        //console.log('javascript moneris:', this.state.iframeJavascript);
    }

    async handleLoadIframeFrFinished(data){
        await this.setState({
            iframeUriFr: data.content.iFrame,
            iframeJavascript: data.content.javascript,
            reCaptchaSiteKey: data.content.reCaptchaSiteKey || ""
        })
        console.log("Successfully loaded moneris - Iframe FR");
    
        if(this.state.iframeUriEn !== ''){
            this.state.bus.emit('unlocked');
        }
        //console.log('javascript moneris:', this.state.iframeJavascript);
    }

    handleLoadMonerisInformationFailed(){
        console.error("Could not load moneris informations");
        StartupService.getEventEmitter().emit('moneris-error');
        this.iframeError = true;
        this.state.bus.emit('unlocked');
    }

    async waitForInformations(){
        if (!this.state.isLoaded) await new Promise(resolve => this.state.bus.once('unlocked', resolve));
    }

    cannotBeLoaded(){
        return this.iframeError;
    }

    getIframeURI(){
        return LocaleService.getLanguage() === 'en' ? this.state.iframeUriEn : this.state.iframeUriFr;
    }

    getReCaptchaSiteKey(){
        return this.state.reCaptchaSiteKey;
    }

}

// Following the Singleton Service pattern (think Angular Service),
// we will instantiate the Container from within this module
const MonerisService = new MonerisServiceContainer();

// Then we will wrap the provider and subscriber inside of functional
// React components. This simplifies the resuse of the module as we
// will be able to import this module as a depenency without having
// to import Unstated and/or create React Contexts  manually in the
// places that we want to Provide/Subscribe to the MonerisService Service.
export const MonerisServiceProvider = props => {
  // We leave the injector flexible, so you can inject a new dependency
  // at any time, eg: snapshot testing
  return <Provider inject={props.inject || [MonerisService]}>{props.children}</Provider>;
};

export const MonerisServiceSubscribe = props => {
  // We also leave the subscribe "to" flexible, so you can have full
  // control over your subscripton from outside of the module
  return <Subscribe to={props.to || [MonerisService]}>{props.children}</Subscribe>;
};

export default MonerisService;