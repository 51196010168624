import { FormFields } from "./FormFields";
import { Address } from "./Address";

export class CcInfo {
    
    private CardInformationAccessKey: string;

    private LastNameCcHolder: string;

    private FirstNameCcHolder: string;

    private CompanyNameCcHolder: string | null;

    private Address: Address;

    constructor(cardInformationAccessKey: string, formFields: FormFields){

        this.CardInformationAccessKey = cardInformationAccessKey;
        this.LastNameCcHolder = formFields.creditCardLastName
        this.FirstNameCcHolder = formFields.creditCardFirstName
        this.CompanyNameCcHolder = formFields.creditCardCompanyName

        this.Address = new Address(formFields);
    }
}


