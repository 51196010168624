export const FakeLocale = [
    {
      "Key": "UpdateCC_FormName",
      "Label_FR": "Solution ePay",
      "Label_EN": "ePay Solution"
    },
    {
      "Key": "ContactUs_OpenHours",
      "Label_FR": "Lundi au vendredi: 6 h 30 à 16 h 30 (Heure du Pacifique)",
      "Label_EN": "Monday to Friday: 6:30 am to 4:30 pm (Pacific Time)"
    },
    {
      "Key": "ContactUs_PhoneNumber",
      "Label_FR": "1 999 999-9999",
      "Label_EN": "1 999 999-9999"
    },
    {
      "Key": "ContactUs_Email",
      "Label_FR": "courriel@courriel.ca",
      "Label_EN": "courriel@courriel.ca"
    },
    {
      "Key": "MemberInfo_Title",
      "Label_FR": "Informations sur le membre",
      "Label_EN": "Member Information"
    },
    {
      "Key": "MemberInfo_PolicyNumber",
      "Label_FR": "Numéro de police collective",
      "Label_EN": "Group Policy No."
    },
    {
      "Key": "MemberInfo_PolicyNumberExample",
      "Label_FR": "Exemple: 000000566",
      "Label_EN": "Example: 000000566"
    },
    {
      "Key": "MemberInfo_SecondaryFieldId",
      "Label_FR": "Matricule",
      "Label_EN": "Member ID"
    },
    {
      "Key": "MemberInfo_SecondaryFieldIdExample",
      "Label_FR": "Exemple: 000123456",
      "Label_EN": "Example: 000123456"
    },
    {
      "Key": "MemberInfo_FullName",
      "Label_FR": "Nom du membre",
      "Label_EN": "Member Name"
    },
    {
      "Key": "Premium_Title",
      "Label_FR": "Prime Totale",
      "Label_EN": "Total Premium"
    },
    {
      "Key": "Premium_Amount",
      "Label_FR": "Montant",
      "Label_EN": "Amount"
    },
    {
      "Key": "CreditCard_FirstName",
      "Label_FR": "Prénom du titulaire de la carte",
      "Label_EN": "Cardholder First Name"
    },
    {
      "Key": "CreditCard_LastName",
      "Label_FR": "Nom du titulaire de la carte",
      "Label_EN": "Cardholder Last Name"
    },
    {
      "Key": "Consent_Part1",
      "Label_FR": "Je comprends que l’Industrielle Alliance, Assurance et services financiers inc. (« la société ») prélèvera la prime mensuelle exigible (plus les taxes applicables) à la carte de crédit indiquée ci-dessus le 1er jour ouvrable de chaque mois ou autour de cette date.  Je comprends que ce montant peut changer éventuellement, comme il est stipulé dans le contrat collectif cadre.  La société va, dans la mesure du possible, me donner un préavis écrit du nouveau montant avant la date d’effet.  La société et moi-même pouvons résilier l’option de paiement mensuel par carte de crédit moyennant un préavis écrit. Je consens à ce que la carte de crédit finissant par",
      "Label_EN": "I understand that Industrial Alliance Insurance and Financial Services Inc. (the \"Company\") will charge the required monthly premium (plus applicable taxes) to the credit card indicated above on or around the 1st  business day of each month. I understand this amount may change at a future date as specified in the Master Group Policy. The Company will, to the best of its ability, advise me in writing of the revised amount in advance of its effective date. The Monthly Credit Card option may be discontinued by me or the Company upon written notice.I consent to the credit card ending with"
    },
    {
      "Key": "Consent_Part2",
      "Label_FR": "soit conservée et utilisée à des fins de paiement pour la présente couverture.  Mon consentement est valide aussi longtemps que la couverture reste en vigueur. La société avertira le détenteur de la carte de tout changement aux termes du présent consentement.",
      "Label_EN": "being retained and used for payment of this coverage.  My consent is valid for as long as the coverage remains in force. The Company will notify the cardholder of any changes to this consent wording."
    },
    {
      "Key": "UpdateCC_ConfirmationSuccessTitle",
      "Label_FR": "Merci!",
      "Label_EN": "Thank you!"
    },
    {
      "Key": "UpdateCC_ConfirmationSuccessSubTitleLine1",
      "Label_FR": "Vos informations de carte de crédit ont été mises à jour et prendront effet dans un délai maximum de 48 heures. Si vous avez des questions, veuillez communiquer avec nous au 1 800 266-5667.",
      "Label_EN": "Your credit card information has been successfully updated and may take up to 48 hours to take effect. If you have any questions, please contact us at 1-800-266-5667."
    },
    {
      "Key": "UpdateCC_ConfirmationSuccessSubTitleLine2",
      "Label_FR": "",
      "Label_EN": ""
    },
    {
      "Key": "UpdateCC_ConfirmationFailTitle",
      "Label_FR": "Nous sommes désolés.",
      "Label_EN": "We're sorry."
    },
    {
      "Key": "UpdateCC_ConfirmationFailSubTitleLine1",
      "Label_FR": "Une erreur est survenue durant le traitement de votre transaction.",
      "Label_EN": "An error occurred during the processing of your transaction."
    },
    {
      "Key": "UpdateCC_ConfirmationFailSubTitleLine2",
      "Label_FR": "Merci de réessayer ou de communiquer avec nous au 1 999 999-9999.",
      "Label_EN": "Please try again or contact us at 1 999 999-9999."
    },
    {
      "Key": "OTP_ConfirmationSuccessTitle",
      "Label_FR": "Merci!",
      "Label_EN": "Thank you!"
    },
    {
      "Key": "OTP_ConfirmationSuccessSubTitleLine1",
      "Label_FR": "Votre paiement a été reçu avec succès.",
      "Label_EN": "Your payment has been successfully received."
    },
    {
      "Key": "OTP_ConfirmationSuccessSubTitleLine2",
      "Label_FR": "Si vous avez des questions, vous pouvez nous joindre au 1 999 999-9999.",
      "Label_EN": "If you have any questions, please contact us at 1 999 999-9999."
    },
    {
      "Key": "OTP_ConfirmationFailTitle",
      "Label_FR": "Nous sommes désolés.",
      "Label_EN": "We're sorry."
    },
    {
      "Key": "OTP_ConfirmationFailSubTitleLine1",
      "Label_FR": "Une erreur est survenue durant le traitement de votre paiement.",
      "Label_EN": "An error occurred during the processing of your payment."
    },
    {
      "Key": "OTP_ConfirmationFailSubTitleLine2",
      "Label_FR": "Merci de réessayer ou de communiquer avec nous au 1 999 999-9999.",
      "Label_EN": "Please try again or contact us at 1 999 999-9999."
    },
    {
      "Key": "RedirectionBtnQuitter",
      "Label_FR": "https://ia.ca/",
      "Label_EN": "https://ia.ca/"
    },
    {
      "Key": "CustomFooterTitle",
      "Label_FR": "© 2018 Solution ePay inc.",
      "Label_EN": "© 2018 ePay Solution inc."
    },
    {
      "Key": "CustomFooterMiddleLine1",
      "Label_FR": "1111, bou. Seigneurial Est, bureau 100, Montréal (Québec) H1H 1O1",
      "Label_EN": "1111, bou. Seigneurial Est, bureau 100, Montréal (Québec) H1H 1O1"
    },
    {
      "Key": "CustomFooterMiddleLine2",
      "Label_FR": "Téléphone : 999 999-9999 | Sans frais : 1 999 999-9999 | Télécopieur : 999 999-9999",
      "Label_EN": "Telephone: 999-999-9999 | Toll free: 1-999-999-9999 | Fax: 999-999-9999"
    },
    {
      "Key": "CustomFooterLastLine",
      "Label_FR": "Courriel : courriel@courriel.ca",
      "Label_EN": "Email: courriel@courriel.ca"
    },
    {
      "Key": "ReviewSection_CustomAgreement",
      "Label_FR": "J’autorise Solution ePay, ses filiales et leurs représentants à communiquer avec moi aux coordonnées indiquées et à accéder à mes renseignements personnels dans le cadre du suivi de ma demande.",
      "Label_EN": "I hereby authorize ePay Solution, its subsidiaries and its representatives to contact me at the address and number provided and access any personal information needed to follow up on my request."
    },
    {
      "Key": "CustomPageTitle",
      "Label_FR": "ePay - Carte de crédit",
      "Label_EN": "ePay - Credit Card"
    }
  ];