import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import StartupService from './services/StartupService'
import LocaleService from './services/LocaleService'
import FormSettingService from './services/FormSettingsService';
import MonerisService from './services/MonerisService';
import { HashRouter, Switch, Route, Router } from 'react-router-dom';
import PageNotFound from './components/error/PageNotFound';
import LoadingScreenService from './services/LoadingScreenService';
import history from './history';


// Root page App.tsx
ReactDOM.render(
  <div>
    <Router history={history}>
      <Switch>
        <Route path="/:ProductOffer" component={App} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  </div>,
document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
