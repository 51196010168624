import React, { Component } from 'react';
import ReviewFormFieldsSection from '../../../sections/ReviewFormFieldsSection/ReviewFormFieldsSection';
import FormAgreementSection from '../../../sections/FormAgreementSection/FormAgreementSection';
import PreviousPageButton from '../../../buttons/PreviousPageButton/PreviousPageButton';
import SubmitFormButton from '../../../buttons/SubmitFormButton/SubmitFormButton';
import history from "../../../../history";
import FormService from '../../../../services/FormService';
import StartupService from '../../../../services/StartupService';
import axios from 'axios';
import { SinglePurchaseRequest } from '../../../../classes/SinglePurchaseRequest';
import { SessionExpireComponent } from '../../../../classes/SessionExpireComponent';
import { LOBProducts } from '../../../../classes/LOBProducts';
import LoadingScreenService from '../../../../services/LoadingScreenService';
import LocaleService from '../../../../services/LocaleService';


class StepReviewFormOTP extends SessionExpireComponent {
  
  SESSION_TIMEOUT_TIME = (15 * 60 * 1000);
  popupButtonAction = this.navigateBack = this.navigateBack.bind(this);
  SINGLE_PURCHASE_ROUTE = "single-purchase"
  constructor(props){
    super(props);

    if(!FormService.hasStoredForm()){
      history.push(`/${StartupService.getProductOffer()}/payment`);
      //window.location.hash = `/${StartupService.getProductOffer()}/payment/form`;
    }
   

    this.submitForm = this.submitForm.bind(this);
    this.initExpirationPopup()
  }

    /**
     * Will enable loading screen and execute Single Purchase action
     */
    async submitForm(){
      await LoadingScreenService.enableLoadingScreen();
      this.executeSinglePurchase();
    }
    
    /**
     * Query backend to make a Single Purchase of the curent product offer
     */
    executeSinglePurchase(){
      var url = StartupService.getApiUrl() + this.SINGLE_PURCHASE_ROUTE;
    
          axios.post(url, new SinglePurchaseRequest(
            LOBProducts.getProductObjectForOffer(StartupService.getProductOffer()).lineOfBusinessCode, 
            LOBProducts.getProductObjectForOffer(StartupService.getProductOffer()).productCode, 
            StartupService.getProductOffer(), 
            FormService.getCardInformationAccessKey(), 
            FormService.getStoredForm(),
            LocaleService.getLanguage()))
          .then((response) => {
            if(response.data.Status === "000"){
              FormService.setConfirmationSuccess();
              FormService.setConfirmationNumber(response.data.Content.ReferenceNumber);
            } else {
              FormService.setConfirmationSuccess(false);
            }

            this.clearSessionTimer();

            //Clear form after use
            FormService.clearStoredForm();

            //window.location.hash = `/${StartupService.getProductOffer()}/payment/confirmation`;
            history.push(`/${StartupService.getProductOffer()}/payment/confirmation`);
            window.scrollTo({top: 0, behavior: 'smooth'});
            LoadingScreenService.disableLoadingScreen();
          })
          .catch((error) => {
            if(error.response.data.Status === "998"){
              FormService.setConfirmationSuccess(false);
              if(typeof error.response.data.Content !== 'undefined' && typeof error.response.data.Content.ReferenceNumber !== 'undefined' ){
                FormService.setConfirmationNumber(error.response.data.Content.ReferenceNumber);
              }
            } else {
              FormService.setConfirmationSuccess(false);
            }
            //window.location.hash = `/${StartupService.getProductOffer()}/payment/confirmation`;
            history.push(`/${StartupService.getProductOffer()}/payment/confirmation`);
            window.scrollTo({top: 0, behavior: 'smooth'});
            LoadingScreenService.disableLoadingScreen();
          });
    }

    navigateBack(){
      this.clearSessionTimer();
      //window.location.hash = `/${StartupService.getProductOffer()}/payment`;
      history.push(`/${StartupService.getProductOffer()}/payment`);
    }



    render() {
  
      return (
        <div className="step-two">
          {this.getSessionTimeoutPopup()}
            <ReviewFormFieldsSection creditCardLastDigits={FormService.getCreditCardLastDigits()} hideAddress={true} />
            <FormAgreementSection creditCardLastDigits={FormService.getCreditCardLastDigits()} />
            <div className="btn-container">
              <PreviousPageButton previousAction={this.navigateBack} />
              <SubmitFormButton submitAction={this.submitForm} />
            </div>
        </div>
      );
    }
  }
  
  
export default StepReviewFormOTP;
  