import React, { Component } from 'react';
import './ExitPageButton.scss'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import FindLabelService from '../../../services/FindLabelService'
import Utils from '../../../utils';



class ExitPageButton extends Component<{isSuccess: boolean, failureAction: any}, {}> {
private link: string;
private isSuccess: boolean;
private failureAction: any;

    constructor(props: any) {
      super(props);

      this.isSuccess = props.isSuccess;
      this.failureAction = props.failureAction;

      this.link = "RedirectionBtnQuitter";
    }

    render() {
      if(this.isSuccess){
        return (
          <a href={Utils.getRedirectionUrl(FindLabelService.findLabel(this.link))}><button id="exit-page-button" className="exit-page-button">{FindLabelService.findLabel("btnExitLabel")}</button></a>
        );
      } else {
        return (
          <button onClick={this.failureAction} id="exit-page-button" className="exit-page-button">{FindLabelService.findLabel("btnExitLabel")}</button>
        );
      }
    }
  }
  
  
export default ExitPageButton;
  