import { FormFields } from "./FormFields";

export class Address {
    
    private StreetNumber: string;

    private Street: string;

    private Appartment: string;

    private City: string;

    private Province: string;

    private Country: string;

    private PostalCode: string;

    private PostalBox: string;

    private Station: string;

    private RuralRoute: string;

    constructor(formFields: FormFields){
        this.StreetNumber = formFields.addressNumber || "";
        this.Street = formFields.addressStreet || "";
        this.Appartment = formFields.addressAppartment || "";
        this.City = formFields.addressCity || "";
        this.Province = formFields.addressProvince || "";
        this.Country =  formFields.addressCountry || "";
        this.PostalCode = formFields.addressPostalCode || "";
        this.PostalBox = formFields.addressPostalBox || "";
        this.Station =  formFields.addressBranch || "";
        this.RuralRoute = formFields.addressRuralRoad || "";
    }
}


