import React, { Component } from 'react';
import './CreditCardInformationSection.scss'
import './IFrameMediaFix.css'
import FindLabelService from '../../../services/FindLabelService'
import MonerisService from '../../../services/MonerisService';
import PosteCanadaComponent from '../../sections/PosteCanadaComponent/PosteCanadaComponent';
import FormErrors from '../../validations/FormErrors/FormErrors';
import { EventEmitter } from 'events';


interface CreditCardInformationSectionProps {
    getParentForm: any;
    parentHandleInputChange: any;
    parentEventEmitter: EventEmitter;
    displayAddress?: boolean;
    displayCardType?: boolean;
}

interface CreditCardInformationSectionState {
    cardType: string;
}

class CreditCardInformationSection extends Component<CreditCardInformationSectionProps, CreditCardInformationSectionState> {

    private spinnerClass = "iframe-with-spinner"
    private spinnerVisible = true;
    private getParentForm: any;
    private parentHandleInputChange: any;
    private displayAddress: boolean;
    private parentEventEmitter: EventEmitter;
    private displayCardType: boolean;

    constructor(props: any) {
        super(props);
        this.getParentForm = props.getParentForm;
        this.parentHandleInputChange = props.parentHandleInputChange;
        this.parentEventEmitter = props.parentEventEmitter;
        this.displayAddress = props.displayAddress;
        this.displayCardType = props.displayCardType;

        this.handelCompanyRadio = this.handelCompanyRadio.bind(this);
        this.changeCopmanyNameValue = this.changeCopmanyNameValue.bind(this);

        this.state = {
            cardType: this.getParentForm().fields.creditCardCompanyName !== null ? "company" : "personal"
        }

    }

    handelCompanyRadio(event: any) {
        const target = event.target;
        const value = target.value;


        if (value === "personal") {
            this.changeCopmanyNameValue(null);
        }
        else if (this.getParentForm().fields.creditCardCompanyName === null) {
            this.changeCopmanyNameValue("");
        }

        this.setState({
            cardType: value
        })
    }

    changeCopmanyNameValue(newValue: any) {
        this.parentHandleInputChange({ target: { value: newValue, name: "creditCardCompanyName"}})
    }


    getPosteCanadaComponent() {
        if (this.displayAddress) {
            return (
                <PosteCanadaComponent parentEventEmitter={this.parentEventEmitter} getParentForm={this.getParentForm} parentHandleInputChange={this.parentHandleInputChange} />
            )
        } else {
            return "";
        }
    }

    getCreditCardLocalInput() {
        return (
            <div>
                {this.displayCardType && this.getCreditCardType()}
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="creditCardFirstName">{FindLabelService.findLabel("CreditCard_FirstName")}</label>
                        <input name="creditCardFirstName" id="creditCardFirstName" value={this.getParentForm().fields.creditCardFirstName} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange} />
                        <FormErrors getValidations={this.getParentForm().getValidations} field={'creditCardFirstName'} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="creditCardLastName">{FindLabelService.findLabel("CreditCard_LastName")}</label>
                        <input name="creditCardLastName" id="creditCardLastName" value={this.getParentForm().fields.creditCardLastName} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange} />
                        <FormErrors getValidations={this.getParentForm().getValidations} field={'creditCardLastName'} />
                    </div>
                </div>
                {this.getPosteCanadaComponent()}
            </div>
        );
    }

    getCreditCardType() {
        return (<div className="cctype">
            <div className="row">
                <h3>{FindLabelService.findLabel("creditCardType")}</h3>
            </div>
            <div className="row cctype-radio">
                <div className="input-column">
                    <input name="creditCardType" id="creditCardTypePersonal" type="radio" value="personal" checked={this.state.cardType === "personal"} onChange={this.handelCompanyRadio} onBlur={this.handelCompanyRadio} />
                    <label htmlFor="creditCardTypePersonal">{FindLabelService.findLabel("creditCardTypePersonal")}</label>
                </div>
            </div>
            <div className="row cctype-radio">
                <div className="input-column">
                    <input name="creditCardType" id="creditCardTypeCompany" type="radio" value="company" checked={this.state.cardType === "company"} onChange={this.handelCompanyRadio} onBlur={this.handelCompanyRadio} />
                    <label htmlFor="creditCardTypeCompany">{FindLabelService.findLabel("creditCardTypeCompany")}</label>
                </div>
            </div>
            <div className="row cctype-subtext">
                <p>{FindLabelService.findLabel("creditCardTypeDisclaimer")}</p>
            </div>
            {this.state.cardType === "company" &&
                <div className="row cctype-name">
                    <div className="input-column">
                        <label htmlFor="creditCardCompanyName">{FindLabelService.findLabel("creditCardCompanyName")}</label>
                        <input name="creditCardCompanyName" id="creditCardCompanyName" value={this.getParentForm().fields.creditCardCompanyName} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange} />
                        <FormErrors getValidations={this.getParentForm().getValidations} field={'creditCardCompanyName'} />
                    </div>
                </div>
            }
        </div>
        );
    }

    hideLoadingSpinner() {
        this.spinnerVisible = false;
        this.forceUpdate();
    }

    buildIframe() {
        if (MonerisService.cannotBeLoaded()) {
            return (
                <div className="row row-error-credit-card-service">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icone_erreur.png"} />
                    <span>{FindLabelService.findLabel("errorContactingCreditCardService")}</span>
                </div>
            );
        } else {
            return (
                <div className={"row " + (this.spinnerVisible ? this.spinnerClass : '')}>
                    <iframe src={MonerisService.getIframeURI()} onLoad={() => this.hideLoadingSpinner()} frameBorder="0" id="AK_iFrame" />
                </div>
            );
        }
    }

    render() {
        return (
            <div className="section credit-card-info-section">
                <h2>{FindLabelService.findLabel("creditCardInfoSectionTitle")}</h2>
                {!this.spinnerVisible ? this.getCreditCardLocalInput() : (!MonerisService.cannotBeLoaded() ? FindLabelService.findLabel("contactingCreditCardService") : '')}
                {this.buildIframe()}
                <FormErrors getValidations={this.getParentForm().getValidations} field={'cardVerificationFailed'} />
            </div>
        );
    }
}

export default CreditCardInformationSection;
