import React, { Component } from 'react';
import './MemberInformationSection.scss'
import FindLabelService from '../../../services/FindLabelService'
import InputMask from 'react-input-mask';
import FindFormSettingService from '../../../services/FindFormSettingService';
import FormErrors from '../../validations/FormErrors/FormErrors';
import Utils from '../../../utils';

interface MemberInformationSectionProps {
  getParentForm: any;
  parentHandleInputChange: any;
}

class MemberInformationSection extends Component<MemberInformationSectionProps> {
  private getParentForm: any;
  private parentHandleInputChange: any;

  constructor(props: any) {
    super(props);

    this.getParentForm = props.getParentForm;
    this.parentHandleInputChange = props.parentHandleInputChange;
    this.fieldIdInputChange = this.fieldIdInputChange.bind(this);
    this.fieldMemberIdInputChange = this.fieldMemberIdInputChange.bind(this);
  }

  /**
   * Will complement the given uid with Zeros at beginning depeding on length given from FormSetting FieldsIdDigits
   * @param valueWithMask - The uid (with mask to be removed before complementing)
   */
  complementFieldIdWithZeros(valueWithMask, length) {
    let value = valueWithMask.replace(/_/g, "");
    let desiredLength = length;
    let valueLength = value.length;
    let lengthDiff = desiredLength - valueLength;
    let complementaryZeros = Array(lengthDiff).fill("0").join('');
    return complementaryZeros + value;
  }

  complementFieldIdWithSpace(valueWithMask, length) {
    let value = valueWithMask.replace(/_/g, "");
    let desiredLength = length;
    let valueLength = value.length;
    let lengthDiff = desiredLength - valueLength;
    let complementaryZeros = Array(lengthDiff).fill("_").join('');
    return complementaryZeros + value;
  }

  fieldIdInputChange(event: any) {
    const target = event.target;

    if (FindFormSettingService.findSetting("FieldsIdAlphanum") === "0") {
      if (FindFormSettingService.findSetting("FieldRefIdAutoFillWithZeros") === "1" && target.value && target.value.replace(/[0]/g, '').length !== 0) {
        target.value = this.complementFieldIdWithZeros(target.value, +FindFormSettingService.findSetting("FieldsIdDigits"));
      }
    }
    else {
        target.value = this.complementFieldIdWithSpace(target.value, +FindFormSettingService.findSetting("FieldsIdDigits"));
    }


    this.parentHandleInputChange(event);
  }

  fieldMemberIdInputChange(event: any) {
    const target = event.target;

    if (FindFormSettingService.findSetting("FieldsMemberIdAlphanum") === "0") {
      if (FindFormSettingService.findSetting("FieldRefIdAutoFillWithZeros") === "1" && target.value && target.value.replace(/[0]/g, '').length !== 0) {
        target.value = this.complementFieldIdWithZeros(target.value, +FindFormSettingService.findSetting("FieldsMemberIdDigits"));
      }
    }
    else {
      target.value = this.complementFieldIdWithSpace(target.value, +FindFormSettingService.findSetting("FieldsMemberIdDigits"));
    }

    this.parentHandleInputChange(event);
  }


  getFieldsIdMask() {
    let desiredLength = +FindFormSettingService.findSetting("FieldsIdDigits");
    let mask = Array(desiredLength).fill("9").join('');
    if (FindFormSettingService.findSetting("FieldsIdAlphanum") === "1") {
      mask = Array(desiredLength).fill("*").join('');
    }

    return mask;
  }

  getFieldsMemberIdMask() {
    let desiredLength = +FindFormSettingService.findSetting("FieldsMemberIdDigits");
    let mask = Array(desiredLength).fill("9").join('');
    if (FindFormSettingService.findSetting("FieldsMemberIdAlphanum") === "1") {
      mask = Array(desiredLength).fill("*").join('');
    }

    return mask;
  }

  getFieldsRows() {
    let containerGlobal: any[] = [];
    let containerFirstRow: any[] = [];
    let containerSecondRow: any[] = [];

    containerGlobal.push(<h2 key={'title-memberinfo'}>{FindLabelService.findLabel("MemberInfo_Title")}</h2>)

    containerFirstRow.push(
      <div className="input-column" key={'field-input-column-gpn'}>
        <label htmlFor="gpn">{FindLabelService.findLabel("MemberInfo_PolicyNumber")}</label>
        <InputMask name="gpn" id="gpn" mask={this.getFieldsIdMask()} maskChar="_" value={this.getParentForm().fields.gpn} onBlur={this.fieldIdInputChange} onChange={this.parentHandleInputChange} />
        <FormErrors getValidations={this.getParentForm().getValidations} field={'gpn'} />
        <div className="field-id-example">
          {FindLabelService.findLabel("MemberInfo_PolicyNumberExample")}
        </div>
      </div>
    )

    if (FindFormSettingService.findSetting("DisplaySecondaryFieldId") === "1") {
      containerFirstRow.push(
        <div className="input-column" key={'field-input-column-memberId'}>
          <label htmlFor="memberId">{FindLabelService.findLabel("MemberInfo_SecondaryFieldId")}</label>
          <InputMask name="memberId" id="memberId" mask={this.getFieldsMemberIdMask()} maskChar="_" value={this.getParentForm().fields.memberId} onBlur={this.fieldMemberIdInputChange} onChange={this.parentHandleInputChange} />
          <FormErrors getValidations={this.getParentForm().getValidations} field={'memberId'} />
          <div className="field-id-example">
            {FindLabelService.findLabel("MemberInfo_SecondaryFieldIdExample")}
          </div>
        </div>
      )
    }

    let firstRow = (<div className="row" key={'row-container-1'}>{containerFirstRow.map((element) => {
      return element
    })}</div>)
    containerGlobal.push(firstRow);


    containerSecondRow.push(
      <div className="input-column" key={'field-input-column-firstname'}>
        <label htmlFor="firstName">{FindLabelService.findLabel("memberInfoSectionFirstName")}</label>
        <input name="firstName" maxLength={30} id="firstName" value={this.getParentForm().fields.firstName} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange} />
        <FormErrors getValidations={this.getParentForm().getValidations} field={'firstName'} />
      </div>
    )
    containerSecondRow.push(
      <div className="input-column" key={'field-input-column-lastname'}>
        <label htmlFor="lastName" >{FindLabelService.findLabel("memberInfoSectionLastName")}</label>
        <input name="lastName" maxLength={30} id="lastName" value={this.getParentForm().fields.lastName} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange} />
        <FormErrors getValidations={this.getParentForm().getValidations} field={'lastName'} />
      </div>
    )

    let secondRow = (<div className="row" key={'row-container-2'}>{containerSecondRow.map((element) => {
      return element
    })}</div>)
    containerGlobal.push(secondRow);


    return (<div className="section member-info-section">{containerGlobal.map((element) => {
      return element
    })}</div>);
  }

  render() {
    return this.getFieldsRows();
  }
}


export default MemberInformationSection;
