import React, { Component } from 'react';
import './CustomButton.scss'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import FindLabelService from '../../../services/FindLabelService'

interface  PreviousPageButtonProps {
  action: any;
  text: string;
  className?: string;
}

class PreviousPageButton extends Component<PreviousPageButtonProps> {
  private action: any;
  private text: string;
  private className: string;

    constructor(props: any) {
      super(props);
  
      this.action = props.action;
      this.className = props.className || "";
      this.text = props.text;
    }
  

    render() {
      return (
        <button onClick={this.action} className={"custom-button " + this.className}>{this.text}</button>
      );
    }
  }
  
  
export default PreviousPageButton;
  