import React, { Component } from 'react';
import './PreviousPageButton.scss'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import FindLabelService from '../../../services/FindLabelService'

interface  PreviousPageButtonProps {
  previousAction: any;
  className?: string;
}

class PreviousPageButton extends Component<PreviousPageButtonProps> {
  private previousAction: any;
  private className: string;

    constructor(props: any) {
      super(props);
  
      this.previousAction = props.previousAction;
      this.className = props.className || "";
    }
  

    render() {
      return (
        <button onClick={this.previousAction} id="previous-page-button" className={"previous-page-button " + this.className}>{FindLabelService.findLabel("btnPreviousLabel")}</button>
      );
    }
  }
  
  
export default PreviousPageButton;
  