import React from "react";
import { Provider, Subscribe, Container } from "unstated";
import LocaleService from "./LocaleService";

export class FindLabelServiceContainer extends Container {
  constructor() {
    super();

    // The state will be available to any component we inject
    // the Container instance into
    this.state = {
    };
  }


  /********************************************************************************
   * These methods will also be avaiable anywhere we inject our container context
   ********************************************************************************/

   /**
   * Retrieve the desired label for current language from piloting table
   * @param {*} labelId - Label Key to retrieve
   */
  findLabel(labelId) {
    var localeObject = LocaleService.getLocale();
    var labelFound = localeObject.find(function(row){
      return row.Key === labelId
    });

    if(labelFound){
      var labelForCurrentLocale = LocaleService.getLanguage() === 'en' ? labelFound.Label_EN : labelFound.Label_FR;
      return labelForCurrentLocale;
    } else {
      console.warn("Could not find label(Returning labelId): " + labelId)
      return labelId;
    }
  }
}

// Following the Singleton Service pattern (think Angular Service),
// we will instantiate the Container from within this module
const FindLabelService = new FindLabelServiceContainer();

// Then we will wrap the provider and subscriber inside of functional
// React components. This simplifies the resuse of the module as we
// will be able to import this module as a depenency without having
// to import Unstated and/or create React Contexts  manually in the
// places that we want to Provide/Subscribe to the FindLabelService Service.
export const FindLabelServiceProvider = props => {
  // We leave the injector flexible, so you can inject a new dependency
  // at any time, eg: snapshot testing
  return <Provider inject={props.inject || [FindLabelService]}>{props.children}</Provider>;
};

export const FindLabelServiceSubscribe = props => {
  // We also leave the subscribe "to" flexible, so you can have full
  // control over your subscripton from outside of the module
  return <Subscribe to={props.to || [FindLabelService]}>{props.children}</Subscribe>;
};

export default FindLabelService;