import { FormFields } from "./FormFields";
import { ClientInformationsRequest } from "./ClientInformationsRequest";

export class SinglePurchaseRequest extends ClientInformationsRequest {
    
    private CardInformationAccessKey: string;

    private Amount: number;

    constructor(lobCode: string, productCode: string, offer: string, cardInformationAccessKey: string, formFields: FormFields, language: string){
        super(lobCode, productCode, offer, cardInformationAccessKey, formFields);

        this.CardInformationAccessKey = cardInformationAccessKey;
        
        if(language === 'en') {
            this.Amount = +(formFields.totalPremium ? formFields.totalPremium.replace(/[$,]/g, '') : 0);
        }
        else {
            this.Amount = +(formFields.totalPremium ? formFields.totalPremium.replace(/,/g, ".").replace(/[$\s]/g, '') : 0);
        }
    }
}