import React, { Component } from "react";
import FindLabelService from "../services/FindLabelService";
import CustomButton from '../components/buttons/CustomButton/CustomButton';

export abstract class SessionExpireComponent extends Component {
    constructor(props) {
        super(props);
        
        this.hideSessionTimeoutPopup = this.hideSessionTimeoutPopup.bind(this);
        this.clearSessionTimer = this.clearSessionTimer.bind(this);
    }
    private sessionExpirationTimer
    private displaySessionTimeoutPopup;
    public sessionExpired;

    public abstract SESSION_TIMEOUT_TIME;

    public abstract popupButtonAction;

    initExpirationPopup(){
        this.sessionExpirationTimer = setTimeout(() => {
            this.displaySessionTimeoutPopup = this.sessionExpired = true;
        }, this.SESSION_TIMEOUT_TIME)
    }

    hideSessionTimeoutPopup(){
        this.displaySessionTimeoutPopup = false;
        this.forceUpdate();
    }

    clearSessionTimer(){
        if(typeof this.sessionExpirationTimer !== 'undefined') clearTimeout(this.sessionExpirationTimer);
    }

    
    getSessionTimeoutPopup(){
        if(this.displaySessionTimeoutPopup){
          return (
            <div className="session-timeout-popup-backdrop" /* onClick={this.hideSessionTimeoutPopup} */>
              <div className="session-timeout-popup">
                <h3>{FindLabelService.findLabel("popupSessionTimeoutMessage")}</h3>
                <CustomButton className={'float-right'} text="OK" action={this.popupButtonAction} />
              </div>
            </div>
          );
        }
        else {
          return "";
        }
      }

}