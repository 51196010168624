import React from "react";
import { Provider, Subscribe, Container } from "unstated";

const LOADING_SCREEN_ELEMENT_ID = "loading-screen-backdrop";
const MAX_ATTEMPS = 5;

// Create a Container for our React Context. This container will
// hold state and methods just like a react component would:
export class LoadingScreenServiceContainer extends Container {

  constructor() {
    super();

    // The state will be available to any component we inject
    // the Container instance into
    this.state = {
      isLoading: true
    };
  }

  /********************************************************************************
   * These methods will also be avaiable anywhere we inject our container context
   ********************************************************************************/

  /**
   * Will make loading screen visible
   */
  async enableLoadingScreen(attempt = 0){
    await this.setState({
      isLoading: true
    })
    setTimeout(() => {
      let loadingElem = document.getElementById(LOADING_SCREEN_ELEMENT_ID);
      if(loadingElem){
        loadingElem.style.display = 'block';
      } else if(attempt !== MAX_ATTEMPS) {
        this.enableLoadingScreen(attempt+1);
      }
    },100)
  }

  /**
   * Will make loading screen invisible
   */
  async disableLoadingScreen(attempt = 0){
    await this.setState({
      isLoading: false
    })
    setTimeout(() => {
      let loadingElem = document.getElementById(LOADING_SCREEN_ELEMENT_ID);
      if(loadingElem){
        loadingElem.style.display = 'none';
      } else if(attempt !== MAX_ATTEMPS){
        this.disableLoadingScreen(attempt+1);
      }
    },100)
  }

  /**
   * Return the loading screen component
   */
  getLoadingScreenComponent(){
    return (
      <div id={LOADING_SCREEN_ELEMENT_ID}>
      </div>
    )
  }

  isEnabled(){
    return this.state.isLoading;
  }
}

// Following the Singleton Service pattern (think Angular Service),
// we will instantiate the Container from within this module
const LoadingScreenService = new LoadingScreenServiceContainer();

// Then we will wrap the provider and subscriber inside of functional
// React components. This simplifies the resuse of the module as we
// will be able to import this module as a depenency without having
// to import Unstated and/or create React Contexts  manually in the
// places that we want to Provide/Subscribe to the LoadingScreenService Service.
export const LoadingScreenServiceProvider = props => {
  // We leave the injector flexible, so you can inject a new dependency
  // at any time, eg: snapshot testing
  return <Provider inject={props.inject || [LoadingScreenService]}>{props.children}</Provider>;
};

export const LoadingScreenServiceSubscribe = props => {
  // We also leave the subscribe "to" flexible, so you can have full
  // control over your subscripton from outside of the module
  return <Subscribe to={props.to || [LoadingScreenService]}>{props.children}</Subscribe>;
};

export default LoadingScreenService;

