export const EmptyForm = {
    gpn: "",
    memberId: "",
    firstName: "",
    lastName: "",
    totalPremium: "",
    creditCardFirstName: "",
    creditCardLastName: "",
    creditCardCompanyName: null,
    addressNumber: "",
    addressStreet: "",
    addressCity: "",
    addressProvince: "",
    addressCountry: "",
    addressPostalCode: "",
    addressAppartment: "",
    addressPostalBox: "",
    addressBranch: "",
    addressRuralRoad: ""
};