import React, { Component } from 'react';
import './NextPageButton.scss'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import FindLabelService from '../../../services/FindLabelService'

interface  NextPageButtonProps {
    btnDisabled?: boolean;
}

class NextPageButton extends Component<NextPageButtonProps> {
    private btnDisabled: boolean;

    constructor(props: any) {
      super(props);
  
      this.btnDisabled = props.btnDisabled;
    }
  

    render() {
  
      return (
        <button disabled={this.btnDisabled} id="next-page-button" className="next-page-button">{FindLabelService.findLabel("btnNextLabel")}</button>
      );
    }
  }
  
  
export default NextPageButton;
  