import React, { Component } from 'react';
import './TotalPremiumSection.scss';
import FindLabelService from '../../../services/FindLabelService'
import NumberFormat from 'react-number-format';
import FormErrors from '../../validations/FormErrors/FormErrors';
import LocaleService from '../../../services/LocaleService';
import FormService from '../../../services/FormService';


interface TotalPremiumSectionProps {
    getParentForm: any;
    parentHandleInputChange: any;
}
class TotalPremiumSection extends Component<TotalPremiumSectionProps> {
    private getParentForm: any;
    private parentHandleInputChange: any;

    constructor(props: any) {
        super(props);

        this.getParentForm = props.getParentForm;
        this.parentHandleInputChange = props.parentHandleInputChange;
        this.convertCurrencyLanguage = this.convertCurrencyLanguage.bind(this);

        LocaleService.getEventEmitter().on('language-changed', this.convertCurrencyLanguage);
    }

    convertCurrencyLanguage() {
        if (LocaleService.getLanguage() === 'en') {
            //From FR to EN
            this.getParentForm().fields.totalPremium = this.getParentForm().fields.totalPremium.replace(/\$/g, '').replace(/\,/g, '.').replace(/\s/g, ',') + '$';
        } else {
            //From EN to FR
            this.getParentForm().fields.totalPremium = '$' + this.getParentForm().fields.totalPremium.replace(/\$/g, '').replace(/\,/g, ' ').replace(/\./g, ',');
        }
    }

    render() {

        return (
            <div className="section total-premium-section">
                <h2>{FindLabelService.findLabel("Premium_Title")}</h2>
                <div className="row">
                    <div className="input-column-small">
                        <label htmlFor="totalPremium">{FindLabelService.findLabel("Premium_Amount")}</label>
                        <NumberFormat name="totalPremium" id="totalPremium" decimalScale={2} inputMode="decimal" decimalSeparator={LocaleService.getLanguage() === 'en' ? '.' : ','} thousandSeparator={LocaleService.getLanguage() === 'en' ? true : ' '} prefix={LocaleService.getLanguage() === 'en' ? '$' : ''} suffix={LocaleService.getLanguage() === 'fr' ? '$' : ''} placeholder={'$'} value={this.getParentForm().fields.totalPremium} onChange={this.parentHandleInputChange} onBlur={this.parentHandleInputChange} />
                        <FormErrors getValidations={this.getParentForm().getValidations} field={'totalPremium'} />
                    </div>
                </div>
            </div>
        );
    }
}


export default TotalPremiumSection;
