import React, { Component } from 'react';
import './ReviewFormFieldsSection.scss'
import FindLabelService from '../../../services/FindLabelService'
import NumberFormat from 'react-number-format';
import FormService from '../../../services/FormService';
import { FormFields } from '../../../classes/FormFields';
import { EmptyForm } from '../../../classes/EmptyForm';
import FindFormSettingService from '../../../services/FindFormSettingService';
import Utils from '../../../utils';
import LocaleService from '../../../services/LocaleService';


class ReviewFormFieldsSection extends Component<{ creditCardLastDigits: string, hideAddress?: boolean }, FormFields> {
    private creditCardLastDigits: string;
    private hideAddress: boolean;

    constructor(props) {
        super(props);

        this.creditCardLastDigits = props.creditCardLastDigits;
        this.hideAddress = props.hideAddress || false;


        if (FormService.hasStoredForm()) {
            this.state = FormService.getStoredForm();
        } else {
            this.state = EmptyForm;
        }
    }

    getMemberInfoFieldsContainers() {
        let containers: any[] = [];
        containers.push(<h2 key={'title-memberinfo-review'} >{FindLabelService.findLabel("MemberInfo_Title")}</h2>)


        containers.push(
            <div key={'row-member-info-field-policynumber'} className="row review-form-field-container">
                <div className="column left-aligned">
                    <span>{FindLabelService.findLabel("MemberInfo_PolicyNumber")}</span>
                </div>
                <div className="column right-aligned">
                    <span id="review-policy-number">{this.state.gpn}</span>
                </div>
            </div>
        )

        if (FindFormSettingService.findSetting("DisplaySecondaryFieldId") === "1") {
            containers.push(
                <div key={'row-member-info-field-memberid'} className="row review-form-field-container">
                    <div className="column left-aligned">
                        <span>{FindLabelService.findLabel("MemberInfo_SecondaryFieldId")}</span>
                    </div>
                    <div className="column right-aligned">
                        <span id="review-member-id">{this.state.memberId}</span>
                    </div>
                </div>
            )
        }

        containers.push(
            <div key={'row-member-info-field-fullname'} className="row review-form-field-container">
                <div className="column left-aligned">
                    <span>{FindLabelService.findLabel("MemberInfo_FullName")}</span>
                </div>
                <div className="column right-aligned">
                    <span id="review-member-name">{this.state.firstName} {this.state.lastName}</span>
                </div>
            </div>
        )

        return (<div className="review-bloc">{containers.map((element) => {
            return element
        })}</div>);
    }

    getCreditCardFieldsContainers() {
        let containers: any[] = [];
        containers.push(<h2 key={'title-creditcardinfo-review'}>{FindLabelService.findLabel("creditCardInfoSectionTitle")}</h2>)


        if (this.state.creditCardCompanyName !== null) {
            containers.push(
                <div key={'row-member-info-field-cardcompany'} className="row review-form-field-container">
                    <div className="column left-aligned">
                        <span>{FindLabelService.findLabel("creditCardCompanyName")}</span>
                    </div>
                    <div className="column right-aligned">
                        <span id="review-credit-card-company-name">{this.state.creditCardCompanyName}</span>
                    </div>
                </div>
            )
        }

        containers.push(
            <div key={'row-member-info-field-cardholder'} className="row review-form-field-container">
                <div className="column left-aligned">
                    <span>{FindLabelService.findLabel("creditCardHolderNameLabel")}</span>
                </div>
                <div className="column right-aligned">
                    <span id="review-credit-card-holder-name">{this.state.creditCardFirstName} {this.state.creditCardLastName}</span>
                </div>
            </div>
        )

        if (!this.hideAddress) {
            containers.push(
                <div key={'row-member-info-field-cardaddress'} className="row review-form-field-container">
                    <div className="column left-aligned">
                        <span>{FindLabelService.findLabel("creditCardAddressLabel")}</span>
                    </div>
                    <div className="column right-aligned">
                        {Utils.getFullAddress(this.state.addressCity, this.state.addressProvince, this.state.addressCountry, this.state.addressPostalCode,
                            this.state.addressNumber, this.state.addressStreet, this.state.addressAppartment,
                            this.state.addressPostalBox, this.state.addressBranch, this.state.addressRuralRoad)}
                    </div>
                </div>
            )
        }

        containers.push(
            <div key={'row-member-info-field-cardnumber'} className="row review-form-field-container">
                <div className="column left-aligned">
                    <span>{FindLabelService.findLabel("creditCardNumberLabel")}</span>
                </div>
                <div className="column right-aligned">
                    <span id="review-credit-card-masked" >**** **** **** {this.creditCardLastDigits}</span>
                </div>
            </div>
        )

        return (<div className="review-bloc">{containers.map((element) => {
            return element
        })}</div>);
    }


    getPremiumFieldContainer() {
        if (this.state.totalPremium) {
            let containers: any[] = [];
            containers.push(<h2 key={'title-premium-review'}>{FindLabelService.findLabel("Premium_Title")}</h2>)


            containers.push(
                <div key={'row-premium-container'} className="row review-form-field-container">
                    <div className="column left-aligned">
                        <span>{FindLabelService.findLabel("Premium_Amount")}</span>
                    </div>
                    <div className="column right-aligned">
                        <span>
                            <NumberFormat id="review-total-premium" value={this.state.totalPremium} inputMode="decimal" decimalScale={2} displayType={'text'} decimalSeparator={LocaleService.getLanguage() === 'en' ? '.' : ','} thousandSeparator={LocaleService.getLanguage() === 'en' ? true : ' '} prefix={LocaleService.getLanguage() === 'en' ? '$' : ''} suffix={LocaleService.getLanguage() === 'fr' ? '$' : ''} />
                        </span>
                    </div>
                </div>
            )

            return (<div className="review-bloc">{containers.map((element) => {
                return element
            })}</div>);
        } else {
            return "";
        }

    }

    render() {

        return (
            <div className="information-review-section">
                {this.getMemberInfoFieldsContainers()}
                {this.getPremiumFieldContainer()}
                {this.getCreditCardFieldsContainers()}
            </div>
        );
    }
}

export default ReviewFormFieldsSection;
