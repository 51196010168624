import React, { Component } from 'react';
import './Footer.scss'
import FindLabelService from '../../../services/FindLabelService'
import FindFormSettingService from '../../../services/FindFormSettingService';

class Footer extends Component {
  private whiteLabelingEnabled: boolean;

  constructor(props){
    super(props);

    this.whiteLabelingEnabled = FindFormSettingService.findSetting("WhiteLabelingEnabled");
  }

  getDesiredContent(){
    if(this.whiteLabelingEnabled){
      let content: any[] = new Array();
      let contentSubTitle: any[] = new Array();
      let customFooterTitle =  FindLabelService.findLabel("CustomFooterTitle");
      let customFooterMiddleLine1 =  FindLabelService.findLabel("CustomFooterMiddleLine1");
      let customFooterMiddleLine2 =  FindLabelService.findLabel("CustomFooterMiddleLine2");
      let customFooterLastLine =  FindLabelService.findLabel("CustomFooterLastLine");

      if(customFooterTitle !== "CustomFooterTitle"){
        content.push(
          <h2 key={'footer-title'}>{FindLabelService.findLabel("CustomFooterTitle")}</h2>
        );
      }

      if(customFooterMiddleLine1 !== "CustomFooterMiddleLine1"){
        contentSubTitle.push(
          <span key={'footer-sub-title-line1'}>{customFooterMiddleLine1}</span>
        );
      }

      if(contentSubTitle.length !== 0){
        contentSubTitle.push(<br key={'footer-spacer'} />);
      }

      if(customFooterMiddleLine2 !== "CustomFooterMiddleLine2"){
        contentSubTitle.push(
          <span key={'footer-sub-title-line2'}>{customFooterMiddleLine2}</span>
        );
      }

      if(contentSubTitle.length > 0){
        let subTitle = (<p className="footer-sub-title" key={'footer-sub-title'}>{contentSubTitle.map((element) => {
          return element
        })}</p>);
        content.push(subTitle);
      }
      
      if(customFooterLastLine !== "CustomFooterLastLine"){
        content.push(
          <p className="footer-last-line" key={'footer-last-line'}>{customFooterLastLine}</p>
        );
      }
      
      let footer = (<footer>
        <div className="footer-wrapper">
          {content.map((element) => {
          return element
        })}</div>
      </footer>);

      return footer;
    } else {
      return (
        <footer>
            <div className="footer-wrapper">
              <h2>{FindLabelService.findLabel("footerTitle")}</h2>
              <p className="footer-sub-title">{FindLabelService.findLabel("footerSubTitle")}</p>
            </div>
        </footer>
      )
    }
  }

  render() {
    return this.getDesiredContent();
  }
}
  
  
export default Footer;
  