import React, { Component } from 'react';
import './PageServiceUnavailable.scss';
import FindLabelService from '../../services/FindLabelService'

class PageServiceUnavailable extends Component {

  render() {

    return (
      <div className="page-service-unavailable">
        <h2>{FindLabelService.findLabel("503_Service_Unavailable") !== "503_Service_Unavailable" ? FindLabelService.findLabel("503_Service_Unavailable") : "Erreur 503 : service indisponible."}</h2>
        <h3>{FindLabelService.findLabel("503_Service_Unavailable_message") !== "503_Service_Unavailable_message" ? FindLabelService.findLabel("503_Service_Unavailable_message") : ""}</h3>
        <p>{FindLabelService.findLabel("503_Service_Unavailable_sub_message") !== "503_Service_Unavailable_sub_message" ? FindLabelService.findLabel("503_Service_Unavailable_sub_message") : ""}</p>
      </div>
    );
  }
}


export default PageServiceUnavailable;
