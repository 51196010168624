import React from "react";
import { Provider, Subscribe, Container } from "unstated";
import StartupService from "./StartupService";
import { environment } from "../environments/environment"
import axios from 'axios';
import EventEmitter from 'events'
import { LOBProducts } from "../classes/LOBProducts";
import { FakeFormSettings } from "../classes/tests/fakeFormSettings";

const FORM_SETTINGS_ROUTE_PREFIX = "parameters/configurations/";
// Create a Container for our React Context. This container will
// hold state and methods just like a react component would:
export class FormSettingsServiceContainer extends Container {


  constructor() {
    super();

    // The state will be available to any component we inject
    // the Container instance into
    this.state = {
      settingsObject: {},
      isLoaded: false,
      bus: new EventEmitter()
    };
  }

  /********************************************************************************
   * These methods will also be avaiable anywhere we inject our container context
   ********************************************************************************/
  //Load locale data
  async loadSettings() {
    if(!environment.loadDummyData){
      this.loadData();
    } else {
      // If loadDummyData flag is on, we load dummy data instead
      this.loadDummyData();
    }
  }

  //Await for unlocked message that will occurs once locale are loaded, then call $callback
  async waitForSettings() {
    if (!this.state.isLoaded) await new Promise(resolve => this.state.bus.once('unlocked', resolve));
  }

  //Load locale data from API
  async loadData(){
    console.log("Loading form settings from API");
    var url = StartupService.getApiUrl() + FORM_SETTINGS_ROUTE_PREFIX + LOBProducts.getProductCombinaisonForOffer(StartupService.getProductOffer());
    
    axios.get(url)
    .then((response) => {
      this.handleLoadSettingsFinished(response.data.Content);
      if(typeof response.data.Maintenance !== 'undefined' && response.data.Maintenance != null){
        StartupService.enableMaintenance(response.data.Maintenance);
      }
    })
    .catch(() => this.handleLoadSettingsFailed());

  }
  
  loadTestData(){
    let data = FakeFormSettings;
    this.handleLoadSettingsFinished(data);
  }

  //Load locale data from hardcoded file (dev purpose)
  async loadDummyData(){
    console.log("Loading form settings from Static Dummy Data");
    axios.get(environment.dummyFormSettingsObjUrl)
    .then((response) => {
      this.handleLoadSettingsFinished(response.data.Content);
    })
    .catch(() => this.handleLoadSettingsFailed());
  }
  
  handleLoadSettingsFailed(){
    console.error("Could not load settings");
    StartupService.getEventEmitter().emit('loading-error');
    this.state.bus.emit('unlocked');
  }

  async handleLoadSettingsFinished(data){
    console.log("Successfully loaded settings");
    await this.setState({ settingsObject:  Object.assign([], data)});
    this.state.bus.emit('unlocked');
  }

  getSettings() {
    return Object.assign([], this.state.settingsObject);
  }

}

// Following the Singleton Service pattern (think Angular Service),
// we will instantiate the Container from within this module
const FormSettingService = new FormSettingsServiceContainer();

// Then we will wrap the provider and subscriber inside of functional
// React components. This simplifies the resuse of the module as we
// will be able to import this module as a depenency without having
// to import Unstated and/or create React Contexts  manually in the
// places that we want to Provide/Subscribe to the FormSettingService Service.
export const FormSettingServiceProvider = props => {
  // We leave the injector flexible, so you can inject a new dependency
  // at any time, eg: snapshot testing
  return <Provider inject={props.inject || [FormSettingService]}>{props.children}</Provider>;
};

export const FormSettingServiceSubscribe = props => {
  // We also leave the subscribe "to" flexible, so you can have full
  // control over your subscripton from outside of the module
  return <Subscribe to={props.to || [FormSettingService]}>{props.children}</Subscribe>;
};

export default FormSettingService;

