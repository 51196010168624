import { FormFields } from "./FormFields";

export class ContractHolderInfo {
    private RefContract: string;
    private RefMember: string;
    private LastName: string;
    private FirstName: string;
    constructor(formFields: FormFields){
        this.RefContract = formFields.gpn
        this.RefMember = formFields.memberId || "";
        this.FirstName = formFields.firstName;
        this.LastName = formFields.lastName;
    }
}