import React, { Component } from 'react';
import './ConfirmationSection.scss'
import FindLabelService from '../../../services/FindLabelService'
import Utils from '../../../utils';


interface  ConfirmationSectionProps {
  formVersion: string;
  referenceNumber?: string;
  isSuccess?: boolean;
}
class ConfirmationSection extends Component<ConfirmationSectionProps> {

  private referenceNumber: string;
  private formVersion: string;
  private isSuccess: boolean;

  PHONE_REGEX = /[1][\s-][0-9]{3}[\s-][0-9]{3}-[0-9]{4}/

  constructor(props: any) {
    super(props);

    this.referenceNumber = props.referenceNumber;
    this.formVersion = props.formVersion;
    this.isSuccess = props.isSuccess || false;
  }

  matchPhoneNumberAndReplaceWithLink(val){
    let results = val.match(this.PHONE_REGEX);

    let elementList: any[] = [];

    if(results){
      for(let i = 0; i < results.length; i++){
        let currentMatch = results[i];

        let curMatchIndex = val.indexOf(currentMatch);
        if(i === 0){
          elementList.push(<span key={Utils.getElementListUniqueKey('confirmation-msg')}>{val.slice(0, curMatchIndex)}</span>) // Cut from 0 to begining of curMatch
          
        } else {
          elementList.push(<span key={Utils.getElementListUniqueKey('confirmation-msg')}>{val.slice((val.indexOf(results[i-1]) + results[i-1].length), curMatchIndex)}</span>) // Cut from lastMatch end to begining of curMatch
        }

        let newValue = this.parsePhoneNumberToLink(currentMatch);
        elementList.push(newValue);

        if(i === results.length - 1){
          elementList.push(<span key={Utils.getElementListUniqueKey('confirmation-msg')}>{val.slice((curMatchIndex + currentMatch.length), val.length)}</span>) // Cut from curMatch end to val end
        }
      }
    } else {
      elementList.push(<span key={Utils.getElementListUniqueKey('confirmation-msg')} >{val}</span>)
    }

    return <p key={Utils.getElementListUniqueKey('confirmation-msg-container')}>{elementList.map((element) => {
      return element
    })}</p>
  }

  parsePhoneNumberToLink(phoneNumber){
    return (
      <a className="phone-link-inline" key={Utils.getElementListUniqueKey('phone-link-inline')} href={'tel:' + phoneNumber}>{phoneNumber}</a>
    )
  }

  getConfirmationComponent(){
    switch(this.formVersion){
      case 'OTP':
        if(this.isSuccess){
          return this.getConfirmationSuccessOTPComponent();
        } else if(typeof this.referenceNumber !== 'undefined') {
          return this.getConfirmationCreditCardServiceFailureOTPComponent();
        } else {
          return this.getConfirmationFailureOTPComponent();
        }
      case 'UpdateCC':
        if(this.isSuccess){
          return this.getConfirmationSuccessUpdateCCComponent();
        } else {
          return this.getConfirmationFailureUpdateCCComponent();
        }
    }
  }

  getConfirmationSuccessOTPComponent(){
    return (
      <div className="section confirmation-section">
        <img className="confirmation-success-img" src={process.env.PUBLIC_URL + "/assets/images/baseline-check_circle_outline.svg"}/>
        <h1 id="confirmation-title">{FindLabelService.findLabel("OTP_ConfirmationSuccessTitle")}</h1>
        {this.matchPhoneNumberAndReplaceWithLink(FindLabelService.findLabel("OTP_ConfirmationSuccessSubTitleLine1"))}
        {this.matchPhoneNumberAndReplaceWithLink(FindLabelService.findLabel("OTP_ConfirmationSuccessSubTitleLine2"))}
        {this.getReferenceNumberDiv()}
      </div>
    );
  }
  getConfirmationFailureOTPComponent(){
    return (
      <div className="section confirmation-section">
        <img className="confirmation-error-img" src={process.env.PUBLIC_URL + "/assets/images/baseline-cancel.svg"}/>
        <h1 id="confirmation-title">{FindLabelService.findLabel("OTP_ConfirmationFailTitle")}</h1>
        {this.matchPhoneNumberAndReplaceWithLink(FindLabelService.findLabel("OTP_ConfirmationFailSubTitleLine1"))}
        {this.matchPhoneNumberAndReplaceWithLink(FindLabelService.findLabel("OTP_ConfirmationFailSubTitleLine2"))}
      </div>
    );
  }
  getConfirmationCreditCardServiceFailureOTPComponent(){
    return (
      <div className="section confirmation-section">
        <img className="confirmation-error-img" src={process.env.PUBLIC_URL + "/assets/images/baseline-cancel.svg"}/>
        <h1 id="confirmation-title">{FindLabelService.findLabel("Confirmation_CreditCardService_FailureTitle")}</h1>
        {this.matchPhoneNumberAndReplaceWithLink(FindLabelService.findLabel("Confirmation_CreditCardService_FailureSubTitleLine1"))}
        {this.matchPhoneNumberAndReplaceWithLink(FindLabelService.findLabel("Confirmation_CreditCardService_FailureSubTitleLine2"))}
        {this.getReferenceNumberDiv()}
      </div>
    );
  }

  getConfirmationSuccessUpdateCCComponent(){
    return (
      <div className="section confirmation-section">
        <img className="confirmation-success-img" src={process.env.PUBLIC_URL + "/assets/images/baseline-check_circle_outline.svg"}/>
        <h1 id="confirmation-title">{FindLabelService.findLabel("UpdateCC_ConfirmationSuccessTitle")}</h1>
        {this.matchPhoneNumberAndReplaceWithLink(FindLabelService.findLabel("UpdateCC_ConfirmationSuccessSubTitleLine1"))}
        {this.matchPhoneNumberAndReplaceWithLink(FindLabelService.findLabel("UpdateCC_ConfirmationSuccessSubTitleLine2"))}
      </div>
    );
  }
  getConfirmationFailureUpdateCCComponent(){
    return (
      <div className="section confirmation-section">
        <img className="confirmation-error-img" src={process.env.PUBLIC_URL + "/assets/images/baseline-cancel.svg"}/>
        <h1 id="confirmation-title">{FindLabelService.findLabel("UpdateCC_ConfirmationFailTitle")}</h1>
        {this.matchPhoneNumberAndReplaceWithLink(FindLabelService.findLabel("UpdateCC_ConfirmationFailSubTitleLine1"))}
        {this.matchPhoneNumberAndReplaceWithLink(FindLabelService.findLabel("UpdateCC_ConfirmationFailSubTitleLine2"))}
      </div>
    );
  }

  getReferenceNumberDiv(){
    if(typeof this.referenceNumber !== 'undefined'){
      return (
        <div className="reference-number-container">
          <div className="column left-aligned">
            <span>{FindLabelService.findLabel("confirmationSectionRefNumber")}</span>
          </div>
          <div className="column right-aligned">
            <span>{this.referenceNumber}</span>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  render() {
    return this.getConfirmationComponent();
  }
}
  
  
export default ConfirmationSection;
