import React, { Component } from 'react';
import './FormErrors.scss'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import FindLabelService from '../../../services/FindLabelService';
import Utils from '../../../utils';


// Add String.Format feature
export function FormatString(this: any, ...val: string[]) {
  let str = this;
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}


interface  FormErrorsProps {
  getValidations: any;
  field: string;
}

class FormErrors extends Component<FormErrorsProps> {

  private getValidations: any;
  private field: string;

    constructor(props: any) {
      super(props);
  
      this.getValidations = props.getValidations;
      this.field = props.field;
    }
  


    render() {
      let errorItems: any[] = [];
      let errors = this.getValidations(this.field);

      if(errors && errors.length > 0){

        errors.forEach(error => {

          let errorMsg = "";

          if (Array.isArray(error)){
            error.forEach((errorPart) => {
              if(typeof errorPart === 'object'){
                errorMsg += `${FormatString.apply(FindLabelService.findLabel(errorPart.message), errorPart.args)} `;
              } else {
                errorMsg += `${FindLabelService.findLabel(errorPart)} `;
              }
            });
          } else {
            if(typeof error === 'object'){
              errorMsg = FormatString.apply(FindLabelService.findLabel(error.message), error.args);
            } else {
              errorMsg = FindLabelService.findLabel(error);
            }
          }

          let currentErrorElement = (<span className="error info-erreur icone-warning" key={Utils.getElementListUniqueKey('validation')}>{errorMsg}</span>);
          errorItems.push(currentErrorElement)
        });

        return <div>{errorItems.map((element) => {
          return element
        })}</div>
      }
      else {
        return "";
      }
  
    }
  }
  
  
export default FormErrors;
  