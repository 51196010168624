import React from "react";
import { Provider, Subscribe, Container } from "unstated";
import FormSettingService from "./FormSettingsService";

export class FindFormSettingServiceContainer extends Container {
  constructor() {
    super();

    // The state will be available to any component we inject
    // the Container instance into
    this.state = {
    };
  }


  /********************************************************************************
   * These methods will also be avaiable anywhere we inject our container context
   ********************************************************************************/

  /**
   * Retrieve the desired config from piloting table
   * @param {*} configId - Config Key to retrieve
   */
  findSetting(configId) {
    var configObject = FormSettingService.getSettings();
    var configFound = configObject.find(function(row){
      return row.Key === configId
    });

    if(configFound){
      return configFound.Value;
    } else {
      /* console.error("Cound not find config(returning false): " + configId) */
      return false;
    }
  }
}

// Following the Singleton Service pattern (think Angular Service),
// we will instantiate the Container from within this module
const FindFormSettingService = new FindFormSettingServiceContainer();

// Then we will wrap the provider and subscriber inside of functional
// React components. This simplifies the resuse of the module as we
// will be able to import this module as a depenency without having
// to import Unstated and/or create React Contexts  manually in the
// places that we want to Provide/Subscribe to the FindFormSettingService Service.
export const FindFormSettingServiceProvider = props => {
  // We leave the injector flexible, so you can inject a new dependency
  // at any time, eg: snapshot testing
  return <Provider inject={props.inject || [FindFormSettingService]}>{props.children}</Provider>;
};

export const FindFormSettingServiceSubscribe = props => {
  // We also leave the subscribe "to" flexible, so you can have full
  // control over your subscripton from outside of the module
  return <Subscribe to={props.to || [FindFormSettingService]}>{props.children}</Subscribe>;
};

export default FindFormSettingService;