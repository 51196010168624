export const PosteCanadaCountyList = [
    /* { iso2: "AF", iso3: "AFG", name: "Afghanistan", name_fr: "Afghanistan", flag: 1 },
    { iso2: "AX", iso3: "ALA", name: "Åland", name_fr: "Åland(les Îles)", flag: 220 },
    { iso2: "AL", iso3: "ALB", name: "Albania", name_fr: "Albanie", alternates: [ "Shqipëria" ], flag: 2 },
    { iso2: "DZ", iso3: "DZA", name: "Algeria", name_fr: "Algérie", flag: 3 },
    { iso2: "AS", iso3: "ASM", name: "American Samoa", name_fr: "Samoa américaines", flag: 4 },
    { iso2: "AD", iso3: "AND", name: "Andorra", name_fr: "Andorre", flag: 5 },
    { iso2: "AO", iso3: "AGO", name: "Angola", name_fr: "Angola", flag: 6 },
    { iso2: "AI", iso3: "AIA", name: "Anguilla", name_fr: "Anguilla", flag: 7 },
    { iso2: "AQ", iso3: "ATA", name: "Antarctica", name_fr: "Antarctique", flag: 0 },
    { iso2: "AG", iso3: "ATG", name: "Antigua and Barbuda", name_fr: "Antigua-et-Barbuda", flag: 8 },
    { iso2: "AR", iso3: "ARG", name: "Argentina", name_fr: "Argentine", flag: 9 },
    { iso2: "AM", iso3: "ARM", name: "Armenia", name_fr: "Arménie", flag: 10 },
    { iso2: "AW", iso3: "ABW", name: "Aruba", name_fr: "Aruba", flag: 11 },
    { iso2: "AU", iso3: "AUS", name: "Australia", name_fr: "Australie", flag: 12 },
    { iso2: "AT", iso3: "AUT", name: "Austria", name_fr: "Autriche", alternates: [ "Österreich" ], flag: 13 },
    { iso2: "AZ", iso3: "AZE", name: "Azerbaijan", name_fr: "Azerbaïdjan", flag: 14 },
    { iso2: "BS", iso3: "BHS", name: "Bahamas", name_fr: "Bahamas", flag: 15 },
    { iso2: "BH", iso3: "BHR", name: "Bahrain", name_fr: "Bahreïn", flag: 16 },
    { iso2: "BD", iso3: "BGD", name: "Bangladesh", name_fr: "Bangladesh", flag: 17 },
    { iso2: "BB", iso3: "BRB", name: "Barbados", name_fr: "Barbade", flag: 18 },
    { iso2: "BY", iso3: "BLR", name: "Belarus", name_fr: "Bélarus", flag: 19 },
    { iso2: "BE", iso3: "BEL", name: "Belgium", name_fr: "Belgique", alternates: [ "België" ], flag: 20 },
    { iso2: "BZ", iso3: "BLZ", name: "Belize", name_fr: "Belize", flag: 21 },
    { iso2: "BJ", iso3: "BEN", name: "Benin", name_fr: "Bénin", flag: 22 },
    { iso2: "BM", iso3: "BMU", name: "Bermuda", name_fr: "Bermudes", flag: 23 },
    { iso2: "BT", iso3: "BTN", name: "Bhutan", name_fr: "Bhoutan", flag: 24 },
    { iso2: "BO", iso3: "BOL", name: "Bolivia", name_fr: "Bolivie, l'État plurinational de la", flag: 25 },
    { iso2: "BQ", iso3: "BES", name: "Bonaire, Sint Eustatius and Saba", name_fr: "Bonaire, Saint-Eustache et Saba", flag: 0 },
    { iso2: "BA", iso3: "BIH", name: "Bosnia and Herzegovina", name_fr: "Bosnie-Herzégovine", alternates: [ "Bosna i Hercegovina" ], flag: 26 },
    { iso2: "BW", iso3: "BWA", name: "Botswana", name_fr: "Botswana", flag: 27 },
    { iso2: "BV", iso3: "BVT", name: "Bouvet Island", name_fr: "Bouvet (l'Île)", flag: 0 },
    { iso2: "BR", iso3: "BRA", name: "Brazil", name_fr: "Brésil", alternates: [ "Brasil" ], flag: 28 },
    { iso2: "IO", iso3: "IOT", name: "British Indian Ocean Territory", name_fr: "Indien (le Territoire britannique de l'océan)", flag: 29 },
    { iso2: "VG", iso3: "VGB", name: "British Virgin Islands", name_fr: "Vierges britanniques (les Îles)", flag: 30 },
    { iso2: "BN", iso3: "BRN", name: "Brunei", name_fr: "Brunei", flag: 0 },
    { iso2: "BG", iso3: "BGR", name: "Bulgaria", name_fr: "Bulgarie", flag: 31 },
    { iso2: "BF", iso3: "BFA", name: "Burkina Faso", name_fr: "Burkina Faso", flag: 32 },
    { iso2: "MM", iso3: "MMR", name: "Burma", name_fr: "Myanmar", flag: 33 },
    { iso2: "BI", iso3: "BDI", name: "Burundi", name_fr: "Burundi", flag: 34 },
    { iso2: "KH", iso3: "KHM", name: "Cambodia", name_fr: "Cambodge", flag: 35 },
    { iso2: "CM", iso3: "CMR", name: "Cameroon", name_fr: "Cameroun", flag: 36 }, */
    { iso2: "CA", iso3: "CAN", name: "Canada", name_fr: "Canada", flag: 37 },
    /* { iso2: "CV", iso3: "CPV", name: "Cape Verde", name_fr: "Cabo Verde", flag: 38 },
    { iso2: "KY", iso3: "CYM", name: "Cayman Islands", name_fr: "Caïmans (les Îles)", flag: 39 },
    { iso2: "CF", iso3: "CAF", name: "Central African Republic", name_fr: "République centrafricaine", flag: 40 },
    { iso2: "TD", iso3: "TCD", name: "Chad", name_fr: "Tchad", flag: 41 },
    { iso2: "CL", iso3: "CHL", name: "Chile", name_fr: "Chili", flag: 42 },
    { iso2: "CN", iso3: "CHN", name: "China", name_fr: "Chine", flag: 43 },
    { iso2: "CX", iso3: "CXR", name: "Christmas Island", name_fr: "Christmas (l'Île)", flag: 0 },
    { iso2: "CC", iso3: "CCK", name: "Cocos (Keeling) Islands", name_fr: "Cocos (les Îles)/ Keeling (les Îles)", flag: 0 },
    { iso2: "CO", iso3: "COL", name: "Colombia", name_fr: "Colombie", flag: 44 },
    { iso2: "KM", iso3: "COM", name: "Comoros", name_fr: "Comores", flag: 45 },
    { iso2: "CG", iso3: "COG", name: "Congo", name_fr: "Congo", flag: 0 },
    { iso2: "CD", iso3: "COD", name: "Congo (Democratic Republic)", name_fr: "Congo (la République démocratique du)", flag: 46 },
    { iso2: "CK", iso3: "COK", name: "Cook Islands", name_fr: "Cook (les Îles)", flag: 47 },
    { iso2: "CR", iso3: "CRI", name: "Costa Rica", name_fr: "Costa Rica", flag: 48 },
    { iso2: "HR", iso3: "HRV", name: "Croatia", name_fr: "Croatie", alternates: [ "Hrvatska" ], flag: 50 },
    { iso2: "CU", iso3: "CUB", name: "Cuba", name_fr: "Cuba", flag: 51 },
    { iso2: "CW", iso3: "CUW", name: "Curaçao", name_fr: "Curaçao", flag: 0 },
    { iso2: "CY", iso3: "CYP", name: "Cyprus", name_fr: "Chypre", flag: 52 },
    { iso2: "CZ", iso3: "CZE", name: "Czechia", name_fr: "tchèque (la République)", alternates: [ "Ceská republika" ], flag: 53 },
    { iso2: "DK", iso3: "DNK", name: "Denmark", name_fr: "Danemark", flag: 54 },
    { iso2: "DJ", iso3: "DJI", name: "Djibouti", name_fr: "Djibouti", flag: 55 },
    { iso2: "DM", iso3: "DMA", name: "Dominica", name_fr: "Dominique", flag: 56 },
    { iso2: "DO", iso3: "DOM", name: "Dominican Republic", name_fr: "dominicaine (la République)", flag: 57 },
    { iso2: "TL", iso3: "TLS", name: "East Timor", name_fr: "Timor-Leste", flag: 0 },
    { iso2: "EC", iso3: "ECU", name: "Ecuador", name_fr: "Équateur", flag: 61 },
    { iso2: "EG", iso3: "EGY", name: "Egypt", name_fr: "Égypte", flag: 58 },
    { iso2: "SV", iso3: "SLV", name: "El Salvador", name_fr: "Salvador", flag: 59 },
    { iso2: "GQ", iso3: "GNQ", name: "Equatorial Guinea", name_fr: "Guinée équatoriale", flag: 62 },
    { iso2: "ER", iso3: "ERI", name: "Eritrea", name_fr: "Érythrée", flag: 63 },
    { iso2: "EE", iso3: "EST", name: "Estonia", name_fr: "Estonie", alternates: [ "Eesti" ], flag: 64 },
    { iso2: "SZ", iso3: "SWZ", name: "Eswatini", name_fr: "Eswatini", flag: 191 },
    { iso2: "ET", iso3: "ETH", name: "Ethiopia", name_fr: "Éthiopie", flag: 65 },
    { iso2: "FK", iso3: "FLK", name: "Falkland Islands", name_fr: "Falkland (les Îles)/Malouines (les Îles)", flag: 66 },
    { iso2: "FO", iso3: "FRO", name: "Faroe Islands", name_fr: "Féroé (les Îles)", flag: 67 },
    { iso2: "FJ", iso3: "FJI", name: "Fiji", name_fr: "Fidji", flag: 68 },
    { iso2: "FI", iso3: "FIN", name: "Finland", name_fr: "Finlande", alternates: [ "Suomi" ], flag: 69 },
    { iso2: "FR", iso3: "FRA", name: "France", name_fr: "France", flag: 70 },
    { iso2: "GF", iso3: "GUF", name: "French Guiana", name_fr: "Guyane française ", flag: 0 },
    { iso2: "PF", iso3: "PYF", name: "French Polynesia", name_fr: "Polynésie française", flag: 71 },
    { iso2: "TF", iso3: "ATF", name: "French Southern Territories", name_fr: "Terres australes françaises", flag: 0 },
    { iso2: "GA", iso3: "GAB", name: "Gabon", name_fr: "Gabon", flag: 72 },
    { iso2: "GM", iso3: "GMB", name: "Gambia", name_fr: "Gambie", flag: 73 },
    { iso2: "GE", iso3: "GEO", name: "Georgia", name_fr: "Géorgie", flag: 74 },
    { iso2: "DE", iso3: "DEU", name: "Germany", name_fr: "Allemagne", alternates: [ "Deutschland" ], flag: 75 },
    { iso2: "GH", iso3: "GHA", name: "Ghana", name_fr: "Ghana", flag: 76 },
    { iso2: "GI", iso3: "GIB", name: "Gibraltar", name_fr: "Gibraltar", flag: 77 },
    { iso2: "GR", iso3: "GRC", name: "Greece", name_fr: "Grèce", alternates: [ "Hellas" ], flag: 79 },
    { iso2: "GL", iso3: "GRL", name: "Greenland", name_fr: "Groenland", flag: 80 },
    { iso2: "GD", iso3: "GRD", name: "Grenada", name_fr: "Grenade", flag: 81 },
    { iso2: "GP", iso3: "GLP", name: "Guadeloupe", name_fr: "Guadeloupe", flag: 0 },
    { iso2: "GU", iso3: "GUM", name: "Guam", name_fr: "Guam", flag: 82 },
    { iso2: "GT", iso3: "GTM", name: "Guatemala", name_fr: "Guatemala", flag: 83 },
    { iso2: "GG", iso3: "GGY", name: "Guernsey", name_fr: "Guernesey", flag: 84 },
    { iso2: "GN", iso3: "GIN", name: "Guinea", name_fr: "Guinée", flag: 85 },
    { iso2: "GW", iso3: "GNB", name: "Guinea-Bissau", name_fr: "Guinée-Bissau", flag: 86 },
    { iso2: "GY", iso3: "GUY", name: "Guyana", name_fr: "Guyana", flag: 87 },
    { iso2: "HT", iso3: "HTI", name: "Haiti", name_fr: "Haïti", flag: 88 },
    { iso2: "HM", iso3: "HMD", name: "Heard Island and McDonald Islands", name_fr: "Heard-et-Îles MacDonald (l'Île)", flag: 0 },
    { iso2: "HN", iso3: "HND", name: "Honduras", name_fr: "Honduras", flag: 89 },
    { iso2: "HK", iso3: "HKG", name: "Hong Kong", name_fr: "Hong Kong", flag: 90 },
    { iso2: "HU", iso3: "HUN", name: "Hungary", name_fr: "Hongrie", alternates: [ "Magyarország" ], flag: 91 },
    { iso2: "IS", iso3: "ISL", name: "Iceland", name_fr: "Islande", alternates: [ "Ísland" ], flag: 92 },
    { iso2: "IN", iso3: "IND", name: "India", name_fr: "Inde", flag: 93 },
    { iso2: "ID", iso3: "IDN", name: "Indonesia", name_fr: "Indonésie", flag: 94 },
    { iso2: "IR", iso3: "IRN", name: "Iran", name_fr: "Iran (République Islamique d')", flag: 95 },
    { iso2: "IQ", iso3: "IRQ", name: "Iraq", name_fr: "Iraq", flag: 96 },
    { iso2: "IE", iso3: "IRL", name: "Ireland", name_fr: "Irlande", flag: 97 },
    { iso2: "IM", iso3: "IMN", name: "Isle of Man", name_fr: "Île de Man", flag: 98 },
    { iso2: "IL", iso3: "ISR", name: "Israel", name_fr: "Israël", flag: 99 },
    { iso2: "IT", iso3: "ITA", name: "Italy", name_fr: "Italie", alternates: [ "Italia" ], flag: 100 },
    { iso2: "CI", iso3: "CIV", name: "Ivory Coast", name_fr: "Côte d'Ivoire", flag: 49 },
    { iso2: "JM", iso3: "JAM", name: "Jamaica", name_fr: "Jamaïque", flag: 101 },
    { iso2: "JP", iso3: "JPN", name: "Japan", name_fr: "Japon", flag: 102 },
    { iso2: "JE", iso3: "JEY", name: "Jersey", name_fr: "Jersey", flag: 103 },
    { iso2: "JO", iso3: "JOR", name: "Jordan", name_fr: "Jordanie", flag: 104 },
    { iso2: "KZ", iso3: "KAZ", name: "Kazakhstan", name_fr: "Kazakhstan", flag: 105 },
    { iso2: "KE", iso3: "KEN", name: "Kenya", name_fr: "Kenya", flag: 106 },
    { iso2: "KI", iso3: "KIR", name: "Kiribati", name_fr: "Kiribati", flag: 107 },
    { iso2: "KP", iso3: "PRK", name: "Korea (North)", name_fr: "Corée (la République populaire démocratique de )", flag: 149 },
    { iso2: "KR", iso3: "KOR", name: "Korea (South)", name_fr: "Corée (la République de)", flag: 185 },
    { iso2: "KW", iso3: "KWT", name: "Kuwait", name_fr: "Koweït", flag: 108 },
    { iso2: "KG", iso3: "KGZ", name: "Kyrgyzstan", name_fr: "Kirghizistan", flag: 109 },
    { iso2: "LA", iso3: "LAO", name: "Laos", name_fr: "Lao, République démocratique populaire", flag: 0 },
    { iso2: "LV", iso3: "LVA", name: "Latvia", name_fr: "Lettonie", flag: 110 },
    { iso2: "LB", iso3: "LBN", name: "Lebanon", name_fr: "Liban", flag: 111 },
    { iso2: "LS", iso3: "LSO", name: "Lesotho", name_fr: "Lesotho", flag: 112 },
    { iso2: "LR", iso3: "LBR", name: "Liberia", name_fr: "Libéria", flag: 113 },
    { iso2: "LY", iso3: "LBY", name: "Libya", name_fr: "Libye", flag: 114 },
    { iso2: "LI", iso3: "LIE", name: "Liechtenstein", name_fr: "Liechtenstein", flag: 115 },
    { iso2: "LT", iso3: "LTU", name: "Lithuania", name_fr: "Lituanie", alternates: [ "Lietuva" ], flag: 116 },
    { iso2: "LU", iso3: "LUX", name: "Luxembourg", name_fr: "Luxembourg", flag: 117 },
    { iso2: "MO", iso3: "MAC", name: "Macao", name_fr: "Macao", flag: 118 },
    { iso2: "MK", iso3: "MKD", name: "Macedonia", name_fr: "Macédoine (l'ex-République yougoslave de)", alternates: [ "Poraneshna Jugoslovenska Republika Makedonija" ], flag: 119 },
    { iso2: "MG", iso3: "MDG", name: "Madagascar", name_fr: "Madagascar", flag: 120 },
    { iso2: "MW", iso3: "MWI", name: "Malawi", name_fr: "Malawi", flag: 121 },
    { iso2: "MY", iso3: "MYS", name: "Malaysia", name_fr: "Malaisie", flag: 122 },
    { iso2: "MV", iso3: "MDV", name: "Maldives", name_fr: "Maldives", flag: 123 },
    { iso2: "ML", iso3: "MLI", name: "Mali", name_fr: "Mali", flag: 124 },
    { iso2: "MT", iso3: "MLT", name: "Malta", name_fr: "Malte", flag: 125 },
    { iso2: "MH", iso3: "MHL", name: "Marshall Islands", name_fr: "Marshall (Îles)", flag: 126 },
    { iso2: "MQ", iso3: "MTQ", name: "Martinique", name_fr: "Martinique", flag: 127 },
    { iso2: "MR", iso3: "MRT", name: "Mauritania", name_fr: "Mauritanie", flag: 128 },
    { iso2: "MU", iso3: "MUS", name: "Mauritius", name_fr: "Maurice", flag: 129 },
    { iso2: "YT", iso3: "MYT", name: "Mayotte", name_fr: "Mayotte", flag: 0 },
    { iso2: "MX", iso3: "MEX", name: "Mexico", name_fr: "Mexique", flag: 130 },
    { iso2: "FM", iso3: "FSM", name: "Micronesia", name_fr: "Micronésie, États fédérés de", flag: 131 },
    { iso2: "MD", iso3: "MDA", name: "Moldova", name_fr: "Moldova , République de", flag: 132 },
    { iso2: "MC", iso3: "MCO", name: "Monaco", name_fr: "Monaco", flag: 133 },
    { iso2: "MN", iso3: "MNG", name: "Mongolia", name_fr: "Mongolie", flag: 134 },
    { iso2: "ME", iso3: "MNE", name: "Montenegro", name_fr: "Monténégro", alternates: [ "Crna Gora" ], flag: 0 },
    { iso2: "MS", iso3: "MSR", name: "Montserrat", name_fr: "Montserrat", flag: 135 },
    { iso2: "MA", iso3: "MAR", name: "Morocco", name_fr: "Maroc", flag: 136 },
    { iso2: "MZ", iso3: "MOZ", name: "Mozambique", name_fr: "Mozambique", flag: 137 },
    { iso2: "NA", iso3: "NAM", name: "Namibia", name_fr: "Namibie", flag: 138 },
    { iso2: "NR", iso3: "NRU", name: "Nauru", name_fr: "Nauru", flag: 139 },
    { iso2: "NP", iso3: "NPL", name: "Nepal", name_fr: "Népal", flag: 140 },
    { iso2: "NL", iso3: "NLD", name: "Netherlands", name_fr: "Pays-Bas", alternates: [ "Holland" ], flag: 141 },
    { iso2: "NC", iso3: "NCL", name: "New Caledonia", name_fr: "Nouvelle-Calédonie", flag: 0 },
    { iso2: "NZ", iso3: "NZL", name: "New Zealand", name_fr: "Nouvelle-Zélande", flag: 142 },
    { iso2: "NI", iso3: "NIC", name: "Nicaragua", name_fr: "Nicaragua", flag: 143 },
    { iso2: "NE", iso3: "NER", name: "Niger", name_fr: "Niger", flag: 144 },
    { iso2: "NG", iso3: "NGA", name: "Nigeria", name_fr: "Nigéria", flag: 145 },
    { iso2: "NU", iso3: "NIU", name: "Niue", name_fr: "Niue", flag: 146 },
    { iso2: "NF", iso3: "NFK", name: "Norfolk Island", name_fr: "Norfolk (l'Île)", flag: 147 },
    { iso2: "MP", iso3: "MNP", name: "Northern Mariana Islands", name_fr: "Mariannes du Nord (les Îles)", flag: 148 },
    { iso2: "NO", iso3: "NOR", name: "Norway", name_fr: "Norvège", flag: 150 },
    { iso2: "OM", iso3: "OMN", name: "Oman", name_fr: "Oman", flag: 151 },
    { iso2: "PK", iso3: "PAK", name: "Pakistan", name_fr: "Pakistan", flag: 152 },
    { iso2: "PW", iso3: "PLW", name: "Palau", name_fr: "Palaos", flag: 153 },
    { iso2: "PS", iso3: "PSE", name: "Palestine", name_fr: "Palestine, État de", flag: 0 },
    { iso2: "PA", iso3: "PAN", name: "Panama", name_fr: "Panama", flag: 154 },
    { iso2: "PG", iso3: "PNG", name: "Papua New Guinea", name_fr: "Papouasie-Nouvelle-Guinée", flag: 155 },
    { iso2: "PY", iso3: "PRY", name: "Paraguay", name_fr: "Paraguay", flag: 156 },
    { iso2: "PE", iso3: "PER", name: "Peru", name_fr: "Pérou", flag: 157 },
    { iso2: "PH", iso3: "PHL", name: "Philippines", name_fr: "Philippines", flag: 158 },
    { iso2: "PN", iso3: "PCN", name: "Pitcairn", name_fr: "Pitcairn", flag: 0 },
    { iso2: "PL", iso3: "POL", name: "Poland", name_fr: "Pologne", alternates: [ "Polska" ], flag: 159 },
    { iso2: "PT", iso3: "PRT", name: "Portugal", name_fr: "Portugal", flag: 160 },
    { iso2: "PR", iso3: "PRI", name: "Puerto Rico", name_fr: "Porto Rico", flag: 161 },
    { iso2: "QA", iso3: "QAT", name: "Qatar", name_fr: "Qatar", flag: 162 },
    { iso2: "RE", iso3: "REU", name: "Réunion", name_fr: "Réunion", flag: 0 },
    { iso2: "RO", iso3: "ROU", name: "Romania", name_fr: "Roumanie", alternates: [ "România" ], flag: 163 },
    { iso2: "RU", iso3: "RUS", name: "Russia", name_fr: "Russie (la Fédération de)", alternates: [ "Rossiya" ], flag: 164 },
    { iso2: "RW", iso3: "RWA", name: "Rwanda", name_fr: "Rwanda", flag: 165 },
    { iso2: "MF", iso3: "MAF", name: "Saint Martin (French)", name_fr: "Saint-Martin (partie française)", flag: 0 },
    { iso2: "WS", iso3: "WSM", name: "Samoa", name_fr: "Samoa", flag: 171 },
    { iso2: "SM", iso3: "SMR", name: "San Marino", name_fr: "Saint-Marin", flag: 172 },
    { iso2: "ST", iso3: "STP", name: "Sao Tome and Principe", name_fr: "Sao Tomé-et-Principe", flag: 173 },
    { iso2: "SA", iso3: "SAU", name: "Saudi Arabia", name_fr: "Arabie saoudite", flag: 174 },
    { iso2: "SN", iso3: "SEN", name: "Senegal", name_fr: "Sénégal", flag: 175 },
    { iso2: "RS", iso3: "SRB", name: "Serbia", name_fr: "Serbie", alternates: [ "Srbija" ], flag: 0 },
    { iso2: "SC", iso3: "SYC", name: "Seychelles", name_fr: "Seychelles", flag: 176 },
    { iso2: "SL", iso3: "SLE", name: "Sierra Leone", name_fr: "Sierra Leone", flag: 177 },
    { iso2: "SG", iso3: "SGP", name: "Singapore", name_fr: "Singapour", flag: 178 },
    { iso2: "SX", iso3: "SXM", name: "Sint Maarten (Dutch)", name_fr: "Saint-Martin (partie néerlandaise)", flag: 0 },
    { iso2: "SK", iso3: "SVK", name: "Slovakia", name_fr: "Slovaquie", alternates: [ "Slovenská republika" ], flag: 179 },
    { iso2: "SI", iso3: "SVN", name: "Slovenia", name_fr: "Slovénie", alternates: [ "Slovenija" ], flag: 180 },
    { iso2: "SB", iso3: "SLB", name: "Solomon Islands", name_fr: "Salomon (Îles)", flag: 181 },
    { iso2: "SO", iso3: "SOM", name: "Somalia", name_fr: "Somalie", flag: 182 },
    { iso2: "ZA", iso3: "ZAF", name: "South Africa", name_fr: "Afrique du Sud", flag: 183 },
    { iso2: "GS", iso3: "SGS", name: "South Georgia and the South Sandwich Islands", name_fr: "Géorgie du Sud-et-les Îles Sandwich du Sud", flag: 184 },
    { iso2: "SS", iso3: "SSD", name: "South Sudan ", name_fr: "Soudan du Sud", flag: 0 },
    { iso2: "ES", iso3: "ESP", name: "Spain", name_fr: "Espagne", alternates: [ "España" ], flag: 186 },
    { iso2: "LK", iso3: "LKA", name: "Sri Lanka", name_fr: "Sri Lanka", flag: 187 },
    { iso2: "BL", iso3: "BLM", name: "St Barthélemy", name_fr: "Saint-Barthélemy", flag: 0 },
    { iso2: "SH", iso3: "SHN", name: "St Helena, Ascension and Tristan da Cunha", name_fr: "Sainte-Hélène, Ascension et Tristan da Cunha", flag: 166 },
    { iso2: "KN", iso3: "KNA", name: "St Kitts and Nevis", name_fr: "Saint-Kitts-et-Nevis", flag: 167 },
    { iso2: "LC", iso3: "LCA", name: "St Lucia", name_fr: "Sainte-Lucie", flag: 168 },
    { iso2: "PM", iso3: "SPM", name: "St Pierre and Miquelon", name_fr: "Saint-Pierre-et-Miquelon", flag: 169 },
    { iso2: "VC", iso3: "VCT", name: "St Vincent", name_fr: "Saint-Vincent-et-les-Grenadines", flag: 170 },
    { iso2: "SD", iso3: "SDN", name: "Sudan", name_fr: "Soudan", flag: 188 },
    { iso2: "SR", iso3: "SUR", name: "Suriname", name_fr: "Suriname", flag: 189 },
    { iso2: "SJ", iso3: "SJM", name: "Svalbard and Jan Mayen", name_fr: "Svalbard et l'Île Jan Mayen", flag: 190 },
    { iso2: "SE", iso3: "SWE", name: "Sweden", name_fr: "Suède", alternates: [ "Sverige" ], flag: 192 },
    { iso2: "CH", iso3: "CHE", name: "Switzerland", name_fr: "Suisse", alternates: [ "Schweiz" ], flag: 193 },
    { iso2: "SY", iso3: "SYR", name: "Syria", name_fr: "République arabe syrienne", flag: 0 },
    { iso2: "TW", iso3: "TWN", name: "Taiwan", name_fr: "Taïwan (Province de Chine)", flag: 194 },
    { iso2: "TJ", iso3: "TJK", name: "Tajikistan", name_fr: "Tadjikistan", flag: 195 },
    { iso2: "TZ", iso3: "TZA", name: "Tanzania", name_fr: "Tanzanie, République-Unie de", flag: 196 },
    { iso2: "TH", iso3: "THA", name: "Thailand", name_fr: "Thaïlande", flag: 197 },
    { iso2: "TG", iso3: "TGO", name: "Togo", name_fr: "Togo", flag: 198 },
    { iso2: "TK", iso3: "TKL", name: "Tokelau", name_fr: "Tokelau", flag: 0 },
    { iso2: "TO", iso3: "TON", name: "Tonga", name_fr: "Tonga", flag: 199 },
    { iso2: "TT", iso3: "TTO", name: "Trinidad and Tobago", name_fr: "Trinité-et-Tobago", flag: 200 },
    { iso2: "TN", iso3: "TUN", name: "Tunisia", name_fr: "Tunisie", flag: 201 },
    { iso2: "TR", iso3: "TUR", name: "Turkey", name_fr: "Turquie", alternates: [ "Türkiye" ], flag: 202 },
    { iso2: "TM", iso3: "TKM", name: "Turkmenistan", name_fr: "Turkménistan", flag: 203 },
    { iso2: "TC", iso3: "TCA", name: "Turks and Caicos Islands", name_fr: "Turks-et-Caïcos (les Îles)", flag: 204 },
    { iso2: "TV", iso3: "TUV", name: "Tuvalu", name_fr: "Tuvalu", flag: 205 },
    { iso2: "UG", iso3: "UGA", name: "Uganda", name_fr: "Ouganda", flag: 206 },
    { iso2: "UA", iso3: "UKR", name: "Ukraine", name_fr: "Ukraine", alternates: [ "Ukraina" ], flag: 207 },
    { iso2: "AE", iso3: "ARE", name: "United Arab Emirates", name_fr: "Émirats arabes unis", alternates: [ "UAE" ], flag: 208 },
    { iso2: "GB", iso3: "GBR", name: "United Kingdom", name_fr: "Royaume-Uni", alternates: [ "Britain", "England", "Great Britain", "Northern Ireland", "Scotland", "UK", "Wales" ], flag: 78 }, */
    { iso2: "US", iso3: "USA", name: "United States", name_fr: "États-Unis", alternates: [ "America", "United States of America" ], flag: 210 }/* ,
    { iso2: "UM", iso3: "UMI", name: "United States Minor Outlying Islands", name_fr: "Îles mineures éloignées des États-Unis", flag: 0 },
    { iso2: "VI", iso3: "VIR", name: "United States Virgin Islands", name_fr: "Vierges des États-Unis (les Îles)", flag: 215 },
    { iso2: "UY", iso3: "URY", name: "Uruguay", name_fr: "Uruguay", flag: 209 },
    { iso2: "UZ", iso3: "UZB", name: "Uzbekistan", name_fr: "Ouzbékistan", flag: 211 },
    { iso2: "VU", iso3: "VUT", name: "Vanuatu", name_fr: "Vanuatu", flag: 212 },
    { iso2: "VA", iso3: "VAT", name: "Vatican City", name_fr: "Saint-Siège [État de la Cité du Vatican]", flag: 0 },
    { iso2: "VE", iso3: "VEN", name: "Venezuela", name_fr: "Venezuela, République bolivarienne du ", flag: 213 },
    { iso2: "VN", iso3: "VNM", name: "Vietnam", name_fr: "Viet Nam", flag: 214 },
    { iso2: "WF", iso3: "WLF", name: "Wallis and Futuna", name_fr: "Wallis-et-Futuna ", flag: 216 },
    { iso2: "EH", iso3: "ESH", name: "Western Sahara", name_fr: "Sahara occidental", flag: 0 },
    { iso2: "YE", iso3: "YEM", name: "Yemen", name_fr: "Yémen", flag: 217 },
    { iso2: "ZM", iso3: "ZMB", name: "Zambia", name_fr: "Zambie", flag: 218 },
    { iso2: "ZW", iso3: "ZWE", name: "Zimbabwe", name_fr: "Zimbabwe", flag: 219 } */
]